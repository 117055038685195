import React from 'react';
import { ColorSettings, H1 } from 'Typography';
import { ButtonXXL } from '../Button';
import i18n from 'i18n';
import * as Constants from 'Util/constants';
import { useHistory } from 'react-router';
import NoOrder from '../Icon/NoOrder';

const NoOrdersComponent = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <NoOrder />
            <H1
                textTitle={i18n.i18n('no_order')}
                textStyle={{
                    color: ColorSettings.colorBlack,
                    fontWeight: 'bold',
                    lineHeight: '7rem',
                }}
            />

            <br />
        </div>
    );
};

export default NoOrdersComponent;
