import { atom, useAtomValue, useSetAtom } from 'jotai';

const productionAtom = atom([]);

export const useProductionAtom = () => useAtomValue(productionAtom);

const writeProductionAtom = atom(null, (get, set, tempData) => {
    set(productionAtom, tempData);
});

export const useSetProductionAtom = () => useSetAtom(writeProductionAtom);
