import { Spin, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ColorSettings, H1 } from '../../Typography';
import './ShowroomInvoice.scss';
import i18n from '../../i18n';
import { ButtonXXS } from '../Button';
import { PrinterOutlined } from '@ant-design/icons';
import Function from '../Service/Function';
import moment from 'moment';
import { numberWithCommas } from '../../Util/constants';
import { useGetTenant } from 'Store/userDetails';
import { replaceNumbersBangla } from 'Util/replaceNumber';
import { useOrderAtom } from 'Store/orderStore';
const cols = [
    {
        title: 'SL',
        dataIndex: 'key',
        key: 'key',
        width: '5rem',
        height: '20rem',
    },
    {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
        width: '25rem',
        height: '20rem',
    },
    {
        title: 'QTY',
        dataIndex: 'quantityBangla',
        key: 'quantity',
        width: '5rem',
        height: '20rem',
    },

    {
        title: 'Bill',
        dataIndex: 'amountBangla',
        key: 'amount',
        width: '5rem',
        height: '20rem',
    },
];

const ShowroomInvoice = () => {
    const tenant = useGetTenant();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [orderInfo, setOrderInfo] = useState({});
    const [bufferCheck, setBufferCheck] = useState(false);
    const [maxProductCountIndex, setMaxProductCountIndex] = useState(0);
    const orderAtom = useOrderAtom();
    let { id } = useParams();

    useEffect(() => {
        console.log('Order Atom: ', orderAtom[0]);

        let maxProductCountIndex = 0;
        let maxProductCount = 0;

        orderAtom.forEach((order, index) => {
            if (order.products.length > maxProductCount) {
                maxProductCount = order.products.length;
                maxProductCountIndex = index;
            }
        });

        orderAtom.length > 1
            ? createAndAdd(orderAtom[maxProductCountIndex], maxProductCountIndex)
            : reConfigureProducts();
    }, []);

    useEffect(() => {
        reConfigureProducts();
    }, [maxProductCountIndex]);
    //create a function to createAndAdd missing products from orderAtom
    const createAndAdd = (data, maxIndex) => {
        let missingProducts = [];
        for (let i = 0; i < orderAtom.length; i++) {
            if (i === maxIndex) continue;
            orderAtom[i].products.forEach(v => {
                console.log('Index:', i, maxIndex);
                if (data.products.filter(x => x.id === v.id).length === 0) {
                    missingProducts.push(v);
                }
            });
        }
        console.log('Missing Products: ', missingProducts);
        setMaxProductCountIndex(maxIndex);
        orderAtom[maxIndex].products.push(...missingProducts);
    };

    const reConfigureProducts = () => {
        console.log('get order list', orderAtom[maxProductCountIndex]);
        let responseJson = orderAtom[maxProductCountIndex];
        let customOrder = responseJson.customOrder;
        let list = responseJson.products
            .sort((a, b) => a.code - b.code)
            .map((v, i) => {
                let price =
                    tenant === 'amaniahotel'
                        ? (parseFloat(v.quantity) * parseFloat(v.price)) / 2
                        : parseFloat(v.quantity) * parseFloat(v.price);
                let key = i + 1;
                if (customOrder?.title === v.name) {
                    console.log('Custom Order: ', customOrder.amount);
                    return {
                        ...v,
                        key: key.toLocaleString('bn-BD'),
                        quantityBangla: replaceNumbersBangla(customOrder.quantity),
                        amountBangla: customOrder.amount.toLocaleString('bn-BD'),
                        amount: customOrder.amount,
                    };
                }
                return {
                    ...v,
                    key: key.toLocaleString('bn-BD'),
                    quantityBangla: replaceNumbersBangla(v.quantity),
                    amountBangla: price.toLocaleString('bn-BD'),
                    amount: price,
                };
            });
        let total = list.map(v => v.amount).reduce((a, b) => a + b, 0);
        responseJson.total = numberWithCommas(parseInt(total));

        setOrderInfo(responseJson);
        if (list.length > 30) {
            storeDataAndCheckBuffer(list);
            setBufferCheck(true);
        } else {
            setData(list);
            setBufferCheck(false);
        }

        setLoading(false);
    };

    const storeDataAndCheckBuffer = data => {
        const bufferLimit = data.length / 2;
        let buffer = [];
        if (data.length > bufferLimit) {
            for (let i = 0; i < data.length; i += bufferLimit) {
                buffer.push(data.slice(i, i + bufferLimit));
            }
            console.log('buffer', buffer);
            setData(buffer);
            return;
        }
    };
    // console.log('Order Info: ', orderInfo);
    return (
        <Spin spinning={loading}>
            <div className="showroom-invoice">
                <div className="print-btn" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ButtonXXS
                        leftIcon={<PrinterOutlined />}
                        onClick={e => window.print()}
                        text={i18n.i18n('print_btn')}
                        style={{
                            backgroundColor: '#4890FC',
                            width: '161px',
                            fontSize: '19px',
                            borderRadius: '5px',
                            boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.25)',
                        }}
                    />
                </div>
                {/* <div style={{ textAlign: 'center' }}>
                    <H1
                        textTitle={i18n.i18n('invoice_title')}
                        textStyle={{
                            color: ColorSettings.colorBlack,
                            fontWeight: 'bold',
                            lineHeight: '5rem',
                        }}
                    />
                </div> */}
                <br />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <H1
                        textTitle={i18n.i18n('invoice_title')}
                        textStyle={{
                            color: ColorSettings.colorBlack,
                            fontWeight: 'bold',
                            lineHeight: '5rem',
                        }}
                    />
                    <p>
                        <span>Date: </span>
                        {moment(orderInfo?.createdAt).add(1, 'days').format('DD-MMM-YYYY')}
                    </p>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <H1
                        textTitle={orderInfo?.userName}
                        textStyle={{
                            color: ColorSettings.colorBlack,
                            fontWeight: 'bold',
                            lineHeight: '5rem',
                        }}
                    />
                </div>
                <br />
                {bufferCheck ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        {data.map((v, i) => (
                            <div style={{ marginRight: i === 0 ? '40px' : 0 }} key={i}>
                                <Table
                                    dataSource={data[i]}
                                    columns={cols}
                                    bordered
                                    pagination={false}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <Table dataSource={data} columns={cols} bordered pagination={false} />
                )}
                {/* <Table dataSource={data} columns={cols} bordered pagination={false} /> */}
                <br />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div>
                        <p style={{ fontWeight: 'bold' }}>
                            মোট মূল্য: {replaceNumbersBangla(orderInfo.total ?? 0)}{' '}
                        </p>
                    </div>
                </div>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between', marginTop: '60px' }}
                >
                    <div>
                        <hr />
                        <p style={{ fontWeight: 'bold' }}>Receiver Sign</p>
                    </div>

                    <div>
                        <hr />
                        <p style={{ fontWeight: 'bold' }}>Authorization Sign</p>
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default ShowroomInvoice;
