import * as React from 'react';

function SvgProductIcon(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13.938 27.5H6.646a2.608 2.608 0 01-2.605-2.605V12.77a2.471 2.471 0 01-.74-3.197 261.872 261.872 0 013.401-5.89A2.47 2.47 0 018.832 2.5a2.58 2.58 0 011.475.481l.03.019c.515.3 5.49 3.261 9.49 5.64l3.548 2.11.163.096a459.62 459.62 0 013.43 2.035 2.454 2.454 0 01.73 3.192 265.707 265.707 0 01-3.402 5.89 2.48 2.48 0 01-2.1 1.188c-.168 0-.336-.018-.5-.056-.145.086-.945.6-1.959 1.25-1.803 1.156-4.268 2.738-4.553 2.884a2.471 2.471 0 01-1.246.271zM6.125 16.288v8.607c0 .288.233.521.521.521h4.779a607.412 607.412 0 01-5.3-9.128zm1.697-1.25c.5.858 5.895 10.165 5.94 10.227a.375.375 0 00.474.102c.169-.095 5.243-3.355 5.293-3.387-.393-.231-3.233-1.924-6.24-3.715L7.798 15l.008.014.013.021v.008l.003-.005zm.593-10.163c-1.01 1.625-3.27 5.66-3.3 5.734a.39.39 0 00.155.482c.166.099 16.692 9.945 16.85 9.97a.43.43 0 00.06 0c.136 0 .262-.07.332-.186 1.018-1.709 3.362-5.821 3.368-5.834a.395.395 0 00-.156-.484c-.159-.095-16.654-9.897-16.76-9.943a.393.393 0 00-.479.146l-.009.015-.01.015-.015.025v.006l-.019.03-.018.024h.001zm1.691 6.863A2.082 2.082 0 1112.19 9.65a2.086 2.086 0 01-2.084 2.084v.004z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgProductIcon;
