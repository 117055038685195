/* eslint-disable no-sparse-arrays */
import './ProductCategory.scss';
import React, { useState, useEffect } from 'react';
import { Table, Tabs, Row, Col, Input, Select, Button, Modal, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import i18n from 'i18n';
import { ColorSettings, H1, H2, H3, H4, H5, TextMenu } from 'Typography';
import { ButtonL, ButtonM, ButtonXXS } from 'Component/Button';
import productItem from 'assets/svg/productItem.svg';
import customNotification from 'Util/Notification';
import MainMenu from 'Component/Menu/mainMenu';
import FuzzySearch from 'fuzzy-search';
import { useHistory } from 'react-router';
import * as Constants from 'Util/constants';

const { TabPane } = Tabs;

global.lan = 'bng';

const AllCategoryList = props => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedAction, setSelectedAction] = useState();
    const [disabled, setDisabled] = useState(true);
    const [catList, setCatList] = useState(props.dataSource);
    const [data, setData] = useState(props.dataSource);
    let history = useHistory();

    useEffect(() => {
        console.log('Cat List:', catList);
        document.title = 'Bakers: All Prouct';
        window.scrollTo(0, 0);
    }, []);

    const columns = [
        {
            title: '',
            dataIndex: 'ImageURL', // this is the value that is parsed from the DB / server side
            render: theImageURL => <img alt={productItem} src={productItem} />, // 'theImageURL' is the variable you must declare in order the render the URL
        },
        {
            title: `${i18n.i18n('cat_col')}`,
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            // defaultSortOrder: 'ascend',
        },
        {
            title: `${i18n.i18n('cat_size_col')}`,
            dataIndex: 'productCount',
            //   defaultSortOrder: 'descend',
            sorter: (a, b) => a.productCount - b.productCount,
        },
        {
            title: `${i18n.i18n('created_col')}`,
            dataIndex: 'createdAt',
        },
        ,
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <div>
                    <Button
                        onClick={e => {
                            history.push(`/category/edit/${record.id}`);
                        }}
                        style={{
                            backgroundColor: '#4890FC',
                            borderRadius: '5px',
                            color: 'white',
                            fontSize: '13px',
                            width: '71px',
                            lineHeight: '19px',
                        }}
                    >
                        {i18n.i18n('edit_btn')}
                    </Button>
                </div>
            ),
        },
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: selectedRowKeys => {
            console.log(selectedRowKeys);
            if (selectedRowKeys.length > 0) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
            setSelectedRowKeys([...selectedRowKeys]);
        },
    };

    const searcher = new FuzzySearch(catList, ['name', 'productCount'], { sort: true });

    const handleSearch = e => {
        // console.log(e);
        const value = e.nativeEvent.data;
        if (value) {
            const result = searcher.search(value);
            if (result.length > 0) setCatList([...result]);
            else setCatList(data);
        } else {
            setCatList(data);
        }
    };

    return (
        <MainMenu>
            <div
                className="product-cat"
                style={{
                    padding: '50px 20px 0 20px',
                }}
            >
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <H1
                            textTitle={i18n.i18n('cat_heading')}
                            textStyle={{
                                color: ColorSettings.colorBlack,
                                fontWeight: 'bold',
                                lineHeight: '5rem',
                            }}
                        />
                        <ButtonM
                            text={i18n.i18n('add_cat_btn')}
                            style={{ margin: '20px 0 0px 20px', fontSize: '19px' }}
                            onClick={() => history.push(Constants.CATEGORY_ADD_ROUTE)}
                        />
                    </div>
                    <div
                        style={{
                            backgroundColor: 'var(--backgroundColor)',
                            padding: '10px 20px 0 20px',
                            borderRadius: '10px',
                            minHeight: '80vh',
                            marginTop: '20px',
                        }}
                    >
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={i18n.i18n('All_text')} key="1"></TabPane>
                        </Tabs>
                        <br />
                        <Row>
                            <Col lg={{ span: 24, offset: 0 }}>
                                <Input
                                    type="search"
                                    size="middle"
                                    placeholder={i18n.i18n('search_product_placeholder')}
                                    onChange={handleSearch}
                                    prefix={<SearchOutlined />}
                                />
                            </Col>
                        </Row>
                        <Table
                            className="product-list-table"
                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={catList}
                        />
                    </div>
                </div>
            </div>
        </MainMenu>
    );
};

export default AllCategoryList;
