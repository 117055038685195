import * as React from 'react';

function SvgUserIcon(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.25 26.25h-2.5V25a8.76 8.76 0 018.75-8.75h5A8.76 8.76 0 0126.25 25v1.25h-2.5V25a6.257 6.257 0 00-6.25-6.25h-5A6.257 6.257 0 006.25 25v1.25zM15 15a6.25 6.25 0 116.25-6.25A6.257 6.257 0 0115 15zm0-10a3.75 3.75 0 100 7.5A3.75 3.75 0 0015 5z"
                fill="#000"
                strokeWidth="2"
            />
        </svg>
    );
}

export default SvgUserIcon;
