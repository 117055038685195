import { atom, useAtomValue, useSetAtom } from 'jotai';

const orderAtom = atom([]);

export const useOrderAtom = () => useAtomValue(orderAtom);

const writeOrderAtom = atom(null, (get, set, tempData) => {
    set(orderAtom, tempData);
});

export const useSetorderAtom = () => useSetAtom(writeOrderAtom);
