import React from 'react'

const NoOrder = () => {
	return (
		<div>
			<svg width="650" height="527" viewBox="0 0 650 527" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M103.221 0L60.2405 83.9027H68.9169V152.322H25.1908L0 201.496L312.133 361.66L415.355 160.164L103.221 0Z" fill="#F2F2F2" />
				<path d="M572.685 54.5796H94.1462V294.862H572.685V54.5796Z" fill="#3F3D56" />
				<path opacity="0.3" d="M514.495 140.918H161.288V141.733H514.495V140.918Z" fill="#F8786F" />
				<path opacity="0.3" d="M514.902 161.282H161.695V162.096H514.902V161.282Z" fill="#F8786F" />
				<path opacity="0.3" d="M514.902 202.007H161.695V202.822H514.902V202.007Z" fill="#F8786F" />
				<path opacity="0.3" d="M514.902 219.927H161.695V220.741H514.902V219.927Z" fill="#F8786F" />
				<path opacity="0.3" d="M514.902 181.645H161.695V182.459H514.902V181.645Z" fill="#F8786F" />
				<path d="M461.189 527C544.791 527 612.563 520.801 612.563 513.153C612.563 505.506 544.791 499.307 461.189 499.307C377.587 499.307 309.814 505.506 309.814 513.153C309.814 520.801 377.587 527 461.189 527Z" fill="#3F3D56" />
				<path d="M171.494 220.517L170.371 219.337L222.946 169.185L269.291 202.549L306.769 160.963L323.203 174.944L367.907 148.099L417.563 181.501L495.497 137.135L496.301 138.551L417.491 183.416L367.858 150.028L323.039 176.942L306.919 163.228L269.524 204.723L223.091 171.297L171.494 220.517Z" fill="#F8786F" />
				<path d="M322.897 485.327C326.722 499.479 339.824 508.243 339.824 508.243C339.824 508.243 346.724 494.064 342.899 479.912C339.074 465.761 325.972 456.997 325.972 456.997C325.972 456.997 319.072 471.176 322.897 485.327Z" fill="#3F3D56" />
				<path d="M328.518 482.286C339.013 492.512 340.295 508.232 340.295 508.232C340.295 508.232 324.56 507.372 314.064 497.146C303.568 486.92 302.286 471.201 302.286 471.201C302.286 471.201 318.022 472.061 328.518 482.286Z" fill="#F8786F" />
				<path opacity="0.4" d="M622.736 70.8701C637.794 70.8701 650 58.6535 650 43.5837C650 28.5139 637.794 16.2974 622.736 16.2974C607.679 16.2974 595.473 28.5139 595.473 43.5837C595.473 58.6535 607.679 70.8701 622.736 70.8701Z" fill="#F8786F" />
				<path opacity="0.4" d="M572.278 75.7569C582.841 75.7569 591.403 67.1872 591.403 56.6158C591.403 46.0444 582.841 37.4746 572.278 37.4746C561.715 37.4746 553.153 46.0444 553.153 56.6158C553.153 67.1872 561.715 75.7569 572.278 75.7569Z" fill="#F8786F" />
				<path opacity="0.4" d="M548.677 417.855C554.295 417.855 558.85 413.296 558.85 407.673C558.85 402.05 554.295 397.492 548.677 397.492C543.058 397.492 538.504 402.05 538.504 407.673C538.504 413.296 543.058 417.855 548.677 417.855Z" fill="#F8786F" />
				<path opacity="0.4" d="M375.328 468.355C379.598 468.355 383.06 464.89 383.06 460.617C383.06 456.343 379.598 452.879 375.328 452.879C371.058 452.879 367.597 456.343 367.597 460.617C367.597 464.89 371.058 468.355 375.328 468.355Z" fill="#F8786F" />
				<path opacity="0.4" d="M349.286 368.169C354.904 368.169 359.459 363.611 359.459 357.988C359.459 352.365 354.904 347.806 349.286 347.806C343.667 347.806 339.113 352.365 339.113 357.988C339.113 363.611 343.667 368.169 349.286 368.169Z" fill="#F8786F" />
				<path d="M494.963 151.915C500.806 151.915 505.543 147.174 505.543 141.326C505.543 135.478 500.806 130.737 494.963 130.737C489.12 130.737 484.383 135.478 484.383 141.326C484.383 147.174 489.12 151.915 494.963 151.915Z" fill="#F8786F" />
				<path d="M426.22 333.117L417.251 334.114C417.251 334.114 413.265 352.067 417.251 376.004C421.237 399.941 426.22 423.878 426.22 423.878C426.22 423.878 430.206 501.674 432.199 502.671C434.192 503.669 452.13 509.653 453.127 503.669C454.123 497.684 454.123 361.044 454.123 361.044L468.075 426.871C468.075 426.871 470.068 509.653 474.054 509.653C478.041 509.653 490.996 508.656 490.996 501.674C490.996 494.692 493.985 402.933 493.985 402.933C493.985 402.933 492.989 335.112 489.003 333.117C485.017 331.122 426.22 333.117 426.22 333.117Z" fill="#2F2E41" />
				<path d="M434.192 499.679C434.192 499.679 425.223 498.682 423.23 499.679C421.237 500.676 418.248 507.658 423.23 511.648C428.213 515.637 441.168 521.621 445.154 521.621C447.919 521.499 450.63 520.82 453.127 519.627L451.134 502.671L434.192 499.679Z" fill="#2F2E41" />
				<path d="M474.054 504.666L475.051 518.629C475.051 518.629 488.006 520.624 489.003 518.629C489.999 516.634 499.965 511.647 500.961 510.65C501.958 509.652 502.954 498.681 496.975 497.684C490.996 496.687 489.003 499.679 489.003 499.679L474.054 504.666Z" fill="#2F2E41" />
				<path d="M390.344 249.337C390.344 249.337 366.427 272.277 369.417 284.246C372.406 296.214 396.324 331.122 396.324 331.122C396.324 331.122 419.244 343.091 422.234 331.122C425.223 319.154 406.289 322.146 406.289 322.146L391.341 286.24L408.282 262.303L390.344 249.337Z" fill="#FFB8B8" />
				<path d="M497.213 214.437C497.213 214.437 519 200.415 518.496 190.781C517.992 181.147 504.857 150.772 504.857 150.772C504.857 150.772 494.582 129.392 490.497 138.129C486.413 146.867 495.866 156.168 495.866 156.168L501.968 185.965L485.385 201.798L497.213 214.437Z" fill="#FFB8B8" />
				<path d="M443.161 194.481C453.068 194.481 461.099 186.444 461.099 176.528C461.099 166.613 453.068 158.576 443.161 158.576C433.255 158.576 425.224 166.613 425.224 176.528C425.224 186.444 433.255 194.481 443.161 194.481Z" fill="#FFB8B8" />
				<path d="M434.192 187.5L437.182 206.45L459.106 202.46C459.106 202.46 458.11 183.51 458.11 181.515C458.11 179.521 434.192 187.5 434.192 187.5Z" fill="#FFB8B8" />
				<path d="M433.196 206.45C433.196 206.45 458.11 200.466 461.099 202.46C464.089 204.455 479.037 206.45 479.037 206.45L495.979 232.382L490.996 265.295C490.996 265.295 489.999 318.156 491.992 327.133C493.985 336.109 504.947 337.106 482.027 339.101C459.106 341.096 455.12 338.104 439.175 342.093C423.23 346.083 410.275 340.098 414.261 336.109C418.248 332.119 425.223 268.287 417.251 262.303C409.279 256.319 406.289 225.4 406.289 225.4C406.289 225.4 433.196 211.437 433.196 206.45Z" fill="#D0CDE1" />
				<path d="M476.047 209.442L479.037 206.45L485.016 200.466C485.016 200.466 504.449 208.944 503.453 211.936C502.456 214.928 495.979 232.382 495.979 232.382L487.01 235.374L476.047 209.442Z" fill="#D0CDE1" />
				<path d="M418.248 227.395L406.289 225.4C406.289 225.4 397.32 235.374 394.331 239.363C391.341 243.353 385.362 253.326 385.362 253.326L406.289 268.287L422.234 251.332L418.248 227.395Z" fill="#D0CDE1" />
				<path d="M449.421 154.778L449.238 153.34C448.018 153.333 446.806 153.524 445.648 153.905C445.529 153.038 445.605 152.155 445.869 151.32C444.692 151.623 443.571 152.111 442.546 152.766L441.822 150.142L431.859 155.055C428.283 156.819 424.513 158.758 422.469 162.183C420.19 166.002 420.586 170.911 422.144 175.077C423.702 179.243 426.291 182.93 428.366 186.864C429.154 188.555 430.101 190.168 431.194 191.68C434.954 196.372 442.274 197.32 447.576 194.488C449.593 193.515 451.201 191.859 452.115 189.813C452.502 188.543 452.804 187.25 453.022 185.941C453.678 183.26 454.439 177.726 456.615 176.03C457.572 175.283 459.47 177.757 460.264 176.838C462.303 174.477 461.875 170.834 460.609 167.982C459.393 165.117 457.973 162.344 456.358 159.684C455.674 158.614 449.399 154.606 449.421 154.778Z" fill="#2F2E41" />
				<path d="M62.4064 93.6768H10.3206V145.806H62.4064V93.6768Z" fill="#3F3D56" />
			</svg>

		</div>
	)
}

export default NoOrder
