import './AllProductStyle.scss';
import React, { useState, useEffect } from 'react';
import { Table, Tabs, Row, Col, Input, Select, Button, Modal, Tooltip, Spin } from 'antd';
import {
    CheckCircleFilled,
    ExclamationCircleOutlined,
    CloseCircleFilled,
    SearchOutlined,
    CheckSquareOutlined,
} from '@ant-design/icons';
import i18n from 'i18n';
import moment from 'moment';
import { ColorSettings, H1, H2, H3, H4, H5, TextMenu } from 'Typography';
import { ButtonL, ButtonM, ButtonXXS } from 'Component/Button';
import productItem from 'assets/svg/productItem.svg';
import customNotification from 'Util/Notification';
import MainMenu from 'Component/Menu/mainMenu';
import FuzzySearch from 'fuzzy-search';
import NoProductViewComponent from 'Component/Product/NoProductViewComponent';
import { useHistory } from 'react-router';
import * as Constants from 'Util/constants';
import Function from 'Component/Service/Function';
import { useGetTenant } from 'Store/userDetails';
const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;
const { confirm, success } = Modal;

global.lan = 'bng';

const optionArr = [
    {
        id: 'unavailable',
        name: 'Make Unavailable',
    },
    {
        id: 'available',
        name: 'Make Available',
    },
    // {
    //     id: 'delete',
    //     name: 'Delete Selected',
    // },
];

const AllProductList = () => {
    const tenant = useGetTenant();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedAction, setSelectedAction] = useState();
    const [noProduct, setNoproduct] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [productList, setProductList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [data, setData] = useState([]);
    let history = useHistory();

    useEffect(() => {
        document.title = 'BakerZ: All Prouct';
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchProductList();
    }, []);

    const fetchProductList = () => {
        setLoading(true);

        try {
            let extraurl = `products?tenant=${tenant}`;

            Function.getFetch(extraurl)
                .then(response => response.json())
                .then(responseJson => {
                    console.log('get product list', responseJson);
                    // let list = [];
                    let list = responseJson._embedded.collection
                        .sort((a, b) => a.code - b.code)
                        .map(v => {
                            return {
                                ...v,
                                price: v.samePriceForAll
                                    ? v.price.regularPrice
                                    : v.price.showroomPrice,
                                createdAt: moment(v.createdAt).format('DD/MM/YYYY'),
                                key: v.id,
                            };
                        });
                    if (list.length > 0) {
                        setProductList(list);
                        setNoproduct(false);
                    } else {
                        setNoproduct(true);
                    }
                    setData(list);
                    setLoading(false);
                    if (
                        responseJson.statusCode == 409 ||
                        responseJson.statusCode == 404 ||
                        responseJson.statusCode == 400 ||
                        responseJson.statusCode == 500
                    ) {
                        alert(responseJson.message);
                        setLoading(false);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    console.warn('Catch Error', err);
                });
        } catch (error) {
            alert(error);
            setLoading(false);
        }
    };

    const columns = [
        {
            title: '',
            dataIndex: 'ImageURL', // this is the value that is parsed from the DB / server side
            render: theImageURL => <img alt={productItem} src={productItem} />, // 'theImageURL' is the variable you must declare in order the render the URL
        },
        {
            title: `${i18n.i18n('product_name_col')}`,
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            // defaultSortOrder: 'ascend',
        },
        {
            title: `${i18n.i18n('code_col')}`,
            dataIndex: 'code',
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.code - b.code,
        },
        {
            title: `${i18n.i18n('unit_col')}`,
            dataIndex: 'unit',
        },
        {
            title: `${i18n.i18n('created_col')}`,
            dataIndex: 'createdAt',
        },
        {
            title: `${i18n.i18n('cat_col')}`,
            dataIndex: 'categoryName',
        },
        {
            title: `${i18n.i18n('price_col')}`,
            dataIndex: 'price',
        },
        {
            title: `${i18n.i18n('status_col')}`,
            // dataIndex: 'sent',
            key: 'sent',
            render: (_, record) =>
                record.status === 'available' ? (
                    <CheckCircleFilled style={{ color: '#52c41a' }} />
                ) : (
                    <CloseCircleFilled style={{ color: '#ff4d4f' }} />
                ),
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <div>
                    <Button
                        onClick={e => {
                            history.push(`/product/edit/${record.id}`);
                        }}
                        style={{
                            backgroundColor: '#4890FC',
                            borderRadius: '5px',
                            color: 'white',
                            fontSize: '13px',
                            width: '71px',
                            lineHeight: '19px',
                        }}
                    >
                        {i18n.i18n('edit_btn')}
                    </Button>
                </div>
            ),
        },
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: selectedRowKeys => {
            console.log('Selected Rows', selectedRowKeys);
            if (selectedRowKeys.length > 0) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
            setSelectedRowKeys([...selectedRowKeys]);
        },
    };

    const deleteProduct = () => {
        // console.log(selectedRowKeys);
        setLoading(true);

        try {
            for (let i = 0; i < selectedRowKeys.length; i++) {
                console.log();
                let extraurl = `products/${selectedRowKeys[i]}?tenant=${tenant}`;

                Function.deleteFetch(extraurl)
                    .then(response => response.json())
                    .then(responseJson => {
                        console.log('del', responseJson);
                        fetchProductList();
                        customNotification('Successful', 'Product deleted successfully', 'success');
                        if (
                            responseJson.statusCode == 409 ||
                            responseJson.statusCode == 404 ||
                            responseJson.statusCode == 400 ||
                            responseJson.statusCode == 500
                        ) {
                            // alert(responseJson.message);
                            customNotification(
                                responseJson.message,
                                'Something went wrong',
                                'warning',
                            );
                        }
                    })
                    .catch(err => console.warn('Catch Error', err));
            }
        } catch (error) {
            // alert(error);
            customNotification(error.message, 'Something went wrong', 'error');
        }
    };

    const updateProductStatus = status => {
        setTableLoading(true);
        console.log(status, selectedRowKeys);

        try {
            for (let i = 0; i < selectedRowKeys.length; i++) {
                console.log(selectedRowKeys[i]);
                let extraurl = `products/${selectedRowKeys[i]}?tenant=${tenant}`;
                let parameter = {
                    status: status,
                };

                Function.postFetch(extraurl, parameter)
                    .then(response => response.json())
                    .then(responseJson => {
                        console.log('update status', responseJson);
                        fetchProductList();
                        if (
                            responseJson.statusCode == 409 ||
                            responseJson.statusCode == 404 ||
                            responseJson.statusCode == 400 ||
                            responseJson.statusCode == 500
                        ) {
                            // alert(responseJson.message);
                            customNotification(
                                responseJson.message,
                                'Something went wrong',
                                'warning',
                            );
                        }
                    })
                    .catch(err => console.warn('Catch Error', err));
            }
            setTableLoading(false);
            customNotification('Successful', 'Product status updated successfully', 'success');
        } catch (error) {
            // alert(error);
            customNotification(error.message, 'Something went wrong', 'error');
        }
    };

    const onActionChange = e => {
        console.log(e);
        if (selectedRowKeys.length > 0) {
            setSelectedAction(e);

            confirm({
                title: `Do you want to 
                ${
                    e == 'available'
                        ? 'change these product status to available?'
                        : e == 'unavailable'
                        ? 'change these product status to unavailable?'
                        : 'delete these items?'
                }`,
                icon: <ExclamationCircleOutlined />,
                content: 'When clicked the OK button, this cannot be reverted',
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk: async () => {
                    if (e == 'available' || e == 'unavailable') updateProductStatus(e);
                    // else deleteProduct();
                    setSelectedAction();
                    setSelectedRowKeys([]);
                },
                onCancel() {
                    setSelectedAction();
                },
            });
        } else {
            setSelectedAction();
            customNotification('No product selected', 'Please select product first', 'warning');
        }
    };

    const searcher = new FuzzySearch(productList, ['name', 'code'], { sort: true });

    const handleSearch = e => {
        // console.log(e);
        const value = e.nativeEvent.data;
        if (value) {
            const result = searcher.search(value);
            if (result.length > 0) setProductList([...result]);
            else setProductList(data);
        } else {
            setProductList(data);
        }
    };

    return (
        <MainMenu>
            <Spin spinning={loading}>
                <div
                    className="all-product"
                    style={{
                        padding: '50px 20px 0 20px',
                    }}
                >
                    {!noProduct ? (
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <H1
                                    textTitle={i18n.i18n('Product_Heading')}
                                    textStyle={{
                                        color: ColorSettings.colorBlack,
                                        fontWeight: 'bold',
                                        lineHeight: '5rem',
                                    }}
                                />
                                <ButtonM
                                    text={i18n.i18n('add_product')}
                                    style={{ margin: '20px 0 0px 20px', fontSize: '19px' }}
                                    onClick={() => history.push(Constants.PRODUCT_ADD_ROUTE)}
                                />
                            </div>
                            <div
                                style={{
                                    backgroundColor: 'var(--backgroundColor)',
                                    padding: '10px 20px 0 20px',
                                    borderRadius: '10px',
                                    minHeight: '80vh',
                                    marginTop: '20px',
                                }}
                            >
                                <Tabs defaultActiveKey="1">
                                    <TabPane tab={i18n.i18n('All_text')} key="1"></TabPane>
                                </Tabs>
                                <br />
                                <Row>
                                    <Col lg={{ span: 24, offset: 0 }}>
                                        <Input
                                            type="search"
                                            size="middle"
                                            placeholder={i18n.i18n('search_product_placeholder')}
                                            onChange={handleSearch}
                                            prefix={<SearchOutlined />}
                                        />
                                    </Col>
                                    <Col lg={{ span: 25, offset: 0 }}>
                                        <br />
                                        <div className="count" style={{ display: 'flex' }}>
                                            <Input
                                                placeholder={
                                                    selectedRowKeys.length > 0
                                                        ? `${selectedRowKeys.length} products selected`
                                                        : 'No product selected'
                                                }
                                                prefix={<CheckSquareOutlined />}
                                                readOnly
                                            />
                                            <Tooltip
                                                placement="rightBottom"
                                                title={disabled ? 'Select product first' : null}
                                            >
                                                <Select
                                                    value={selectedAction}
                                                    showArrow
                                                    allowClear
                                                    style={{ width: 250 }}
                                                    placeholder={i18n.i18n('action_placeholder')}
                                                    onChange={onActionChange}
                                                    mode="single"
                                                    size="large"
                                                    disabled={disabled}
                                                >
                                                    {optionArr.map(v => (
                                                        <Option key={v.id} value={v.id}>
                                                            {v.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Tooltip>
                                        </div>
                                    </Col>
                                </Row>
                                <Table
                                    rowClassName={(record, index) =>
                                        record.status == 'available' ? 'available' : 'unavailable'
                                    }
                                    className="product-list-table"
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={productList}
                                    loading={tableLoading}
                                    pagination={{
                                        defaultPageSize: 50,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['25', '50', '100'],
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <NoProductViewComponent width="566" height="504" />
                        </div>
                    )}
                </div>
            </Spin>
        </MainMenu>
    );
};

export default AllProductList;
