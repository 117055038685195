import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Alert, Spin } from 'antd';
import './profileDashboardStyle.scss';
import { H2, ColorSettings } from 'Typography';
import i18n from 'i18n';
import { MainMenu, InputL } from 'Component';
import { useGetTenant } from 'Store/userDetails';
import { InfoCircleOutlined } from '@ant-design/icons';
import Function from 'Component/Service/Function';

const ProfileDashboard = () => {
    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState('optional');
    const [showAlert, setShowAlert] = useState(false);
    const [showSuccessAlert, setSuccessAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const tenant = useGetTenant();

    // useEffect(() => {
    //     renderShowAlert();
    // }, [showAlert])

    const normalUserInput = () => {
        return (
            <Form
                name="basic"
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 12,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Spin spinning={loading}>
                    <Form.Item
                        label="Name"
                        name="Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your name!',
                            },
                        ]}
                    >
                        <Input allowClear placeHolder="Azimpur Branch-1" />
                    </Form.Item>
                    <Form.Item
                        label="Phone Number"
                        name="phoneNumber"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your phone number!',
                            },
                        ]}
                    >
                        <Input maxLength={11} allowClear placeHolder="01XXXXXXXXX" />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your email!',
                            },
                        ]}
                    >
                        <Input type="email" allowClear placeHolder="demo@demo.com" />
                    </Form.Item>
                    <Form.Item
                        label="User Type"
                        name="userType"
                        rules={[
                            {
                                required: true,
                                message: 'Please select from this list!',
                            },
                        ]}
                    >
                        <Select defaultValue="placeholder">
                            <Select.Option value="placeholder">Select user type</Select.Option>
                            <Select.Option value="showroom">Showroom</Select.Option>
                            <Select.Option value="dealer">Dealer</Select.Option>
                            <Select.Option value="salesman">Salesman</Select.Option>
                            <Select.Option value="corporate">Corporate</Select.Option>
                            <Select.Option value="manager">Manager</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.userType !== currentValues.userType
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('userType') === 'manager' ? (
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Spin>
            </Form>
        );
    };
    const onFinish = values => {
        let parameter = {
            tenant: tenant,
            displayName: values.Name,
            userType: values.userType,
            phoneNumber: '+88' + values.phoneNumber,
        };

        let parameterForManager = {
            tenant: tenant,
            displayName: values.Name,
            userType: values.userType,
            phoneNumber: '+88' + values.phoneNumber,
            password: values.password,
            email: values.email,
        };
        values.password ? createUser(parameterForManager) : createUser(parameter);
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    // const fetchEncrytedPassword = values => {
    //     try {
    //         const extraurl = 'encrypt?text=' + values.password;

    //         Function.getFetch(extraurl)
    //             .then(response => response.json())
    //             .then(responseJson => {
    //                 console.log('get Encrypted Password', responseJson);
    //                 let parameter = {
    //                     tenant: tenant,
    //                     displayName: values.Name,
    //                     userType: values.userType,
    //                     phoneNumber: '+88' + values.phoneNumber,
    //                     password: responseJson?.encryptedText,
    //                     email: values.email,
    //                 };

    //                 createUser(parameter);
    //             })
    //             .catch(err => {
    //                 setShowAlert(true);
    //                 console.warn('Catch Error', err);
    //             });
    //     } catch (error) {
    //         console.log('Error in Catch', error);
    //         alert(error);
    //     }
    // };

    const createUser = userData => {
        try {
            setLoading(true);
            let extraurl = 'user';
            Function.postFetch(extraurl, userData)
                .then(response => response.json())
                .then(responseJson => {
                    console.log('Create User', responseJson);
                    if (
                        responseJson.statusCode === 409 ||
                        responseJson.statusCode === 404 ||
                        responseJson.statusCode === 400 ||
                        responseJson.statusCode === 500
                    ) {
                        //  alert(responseJson.message);

                        setShowAlert(true);
                    } else {
                        setSuccessAlert(true);
                    }
                    setLoading(false);
                })
                .catch(err => console.warn('Catch Error', err));
        } catch (error) {
            setLoading(false);
            alert(error);
        }
    };

    const onCloseAlert = () => {
        setShowAlert(false);
    };

    const renderShowAlert = () => {
        return (
            <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                {console.log('Show Alert ' + showAlert)}
                <Alert
                    message="ATTENTION"
                    description="This account is already taken!"
                    type="error"
                    onClose={() => onCloseAlert()}
                    showIcon
                    closable
                />
            </div>
        );
    };

    const renderSuccessAlert = () => {
        return (
            <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                <Alert
                    message="Success"
                    description="User Created Successfully!"
                    type="success"
                    showIcon
                    closable
                    onClose={() => setSuccessAlert(false)}
                />
            </div>
        );
    };

    return (
        <MainMenu>
            {showAlert == true ? renderShowAlert() : null}
            {showSuccessAlert === true ? renderSuccessAlert() : null}

            <div className="headerText">
                <H2
                    textTitle={i18n.i18n('add_new_user')}
                    textStyle={{ fontWeight: 'bold', marginBottom: 20 }}
                />
            </div>
            {normalUserInput()}
        </MainMenu>
    );
};

export default ProfileDashboard;
