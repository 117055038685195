import './style.css';

import { Layout, Menu, Badge } from 'antd';
import { useEffect, useState, useContext } from 'react';
import { useLocation, withRouter } from 'react-router';

import * as Constants from 'Util/constants';
import { ColorSettings } from 'Typography';
import {
    Home,
    Logo,
    ProductIcon,
    SalesGraph,
    ShoppingCart,
    UserIcon,
    PaymentIcon,
    Logout,
} from '../Icon';
import { OrderContext } from 'Context/OrderContext';
import { useSetTenant } from 'Store/userDetails';
import { useIsAuthenticated } from 'Store/authToken';

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

const MainMenu = props => {
    const [collapsed, setCollapsed] = useState(false);

    const url = useLocation();

    const [path, setPath] = useState('/');
    const { customOrderData } = useContext(OrderContext);
    const [, setIsUserAuthenticated] = useIsAuthenticated();
    const setTenant = useSetTenant();
    useEffect(() => {
        console.log(url.pathname);
        setPath(url.pathname);
    }, [props.history, url.pathname]);

    const onCollapse = () => {
        console.log(collapsed);
        setCollapsed(!collapsed);
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                breakpoint="md"
                collapsedWidth="0"
                onBreakpoint={broken => {
                    console.log(broken);
                }}
                style={{
                    background: ColorSettings.colorWhite,
                    minWidth: '200px',
                }}
                width={'224px'}
                // collapsible
                collapsed={collapsed}
                onCollapse={onCollapse}
            >
                <div className="logo" style={{ marginLeft: '20px' }}>
                    <Logo width={'12em'} height={'4em'} alt="Bakers Logo" />
                </div>
                <Menu
                    theme="light"
                    defaultSelectedKeys={[path]}
                    mode="inline"
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderColor: '#f0f0f0',
                    }}
                >
                    {/* <Menu.Item
                        key={Constants.DASHBOARD_ROUTE}
                        onClick={() => props.history.push(Constants.DASHBOARD_ROUTE)}
                        icon={
                            <Home
                                width="20"
                                height="20"
                                style={{
                                    marginRight: 24,
                                    marginBottom: '-4px',
                                }}
                            />
                        }
                    >
                        Dashboard
                    </Menu.Item> */}
                    <SubMenu
                        key="sub2"
                        icon={<ProductIcon width="20" height="20" style={{ marginRight: 24 }} />}
                        title="Products"
                    >
                        <Menu.Item
                            key={Constants.PRODUCT_ROUTE}
                            onClick={() => props.history.push(Constants.PRODUCT_ROUTE)}
                        >
                            All Products
                        </Menu.Item>
                        <Menu.Item
                            key={Constants.PRODUCT_ADD_ROUTE}
                            onClick={() => props.history.push(Constants.PRODUCT_ADD_ROUTE)}
                        >
                            Add Product
                        </Menu.Item>
                        <Menu.Item
                            key={Constants.CATEGORY_ROUTE}
                            onClick={() => props.history.push(Constants.CATEGORY_ROUTE)}
                        >
                            Category
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item
                        key={Constants.SALES_ROUTE}
                        onClick={() => props.history.push(Constants.SALES_ROUTE)}
                        icon={
                            <SalesGraph
                                src={SalesGraph}
                                width="20"
                                height="20"
                                style={{ marginRight: 24 }}
                            />
                        }
                    >
                        Sales
                    </Menu.Item>
                    <SubMenu
                        key="sub4"
                        icon={
                            <ShoppingCart
                                src={ShoppingCart}
                                width="20"
                                height="20"
                                style={{ marginRight: 24 }}
                            />
                        }
                        title="Orders"
                    >
                        <Menu.Item
                            key={Constants.SHOWROOM_ORDER_ROUTE}
                            onClick={() => props.history.push(Constants.SHOWROOM_ORDER_ROUTE)}
                        >
                            Showroom Order
                        </Menu.Item>
                        <Menu.Item
                            key={Constants.PRODUCTION_ORDER_ROUTE}
                            onClick={() => props.history.push(Constants.PRODUCTION_ORDER_ROUTE)}
                        >
                            Production
                        </Menu.Item>
                        <Menu.Item
                            key={Constants.FACTORY_ORDER_ROUTE}
                            onClick={() => props.history.push(Constants.FACTORY_ORDER_ROUTE)}
                        >
                            Factory Order
                        </Menu.Item>

                        <Menu.Item
                            key={Constants.CUSTOM_ORDER_ROUTE}
                            onClick={() => props.history.push(Constants.CUSTOM_ORDER_ROUTE)}
                        >
                            Custom Order <Badge count={customOrderData.length} />
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item
                        key={Constants.PROFILE_ROUTE}
                        onClick={() => props.history.push(Constants.PROFILE_ROUTE)}
                        icon={<UserIcon width="20" height="20" />}
                    >
                        User Profile
                    </Menu.Item>
                    <Menu.Item
                        key={'logout'}
                        onClick={() => {
                            setIsUserAuthenticated(false);
                            setTenant(null);
                            localStorage.setItem('tenant', '');
                            localStorage.setItem('isAuthenticated', false);
                        }}
                        icon={<Logout width="20" height="20" />}
                    >
                        Logout
                    </Menu.Item>
                    {/* <Menu.Item
                        key={Constants.PAYMENT_ROUTE}
                        onClick={() => props.history.push(Constants.PAYMENT_ROUTE)}
                        icon={<PaymentIcon width="20" height="20" />}
                    >
                        Payment
                    </Menu.Item> */}
                </Menu>
            </Sider>
            <Layout className="site-layout" style={{ background: ColorSettings.colorWhite }}>
                <Content style={{ margin: '0 16px' }}>{props.children}</Content>
            </Layout>
        </Layout>
    );
};

export default withRouter(MainMenu);
