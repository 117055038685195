import './style.css';

// var getFontStyle = (style) => {
// 	var baseFontFamily = {};
// 	if(typeof style !== "undefined") {
// 		(Array.isArray(style) ? style : [style]).forEach((style) => {
// 		    if (style && style.fontWeight) {
// 		      	baseFontFamily = {
// 			      	fontFamily: typeof style.fontWeight !== "undefined" ?
// 			      		style.fontWeight == 'normal' ? 'KohinoorBangla-Regular' : 'KohinoorBangla'
// 			      		: 'KohinoorBangla-Regular'
// 		      	};
// 		    }
// 		});
// 	} else {
// 		baseFontFamily = {
// 			fontFamily: 'KohinoorBangla-Regular'
// 		}
// 	}
// 	return baseFontFamily;
// }

var H1 = props => {
    return (
        <div>
            <text className="H1" id={props.id} style={props.textStyle}>
                {props.textTitle}
            </text>
        </div>
    );
};

var H2 = props => {
    return (
        <div>
            <text className="H2" id={props.id} style={props.textStyle}>
                {props.textTitle}
            </text>
        </div>
    );
};

var H3 = props => {
    return (
        <div>
            <text className="H3" id={props.id} style={props.textStyle}>
                {props.textTitle}
            </text>
        </div>
    );
};

var H4 = props => {
    return (
        <div>
            <text className="H4" id={props.id} style={props.textStyle}>
                {props.textTitle}
            </text>
        </div>
    );
};
var H5 = props => {
    return (
        <div>
            <text className="H5" style={props.textStyle}>
                {props.textTitle}
            </text>
        </div>
    );
};

var TextMenu = props => {
    var styleing = {
        fontFamily: 'DMSans-Regular',
    };
    return (
        <div>
            <text className="textMenu" style={{ ...styleing, ...props.textStyle }}>
                {props.textTitle}
            </text>
        </div>
    );
};

export { H1, H2, H3, H5, H4, TextMenu };
