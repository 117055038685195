import { Spin } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import MainMenu from 'Component/Menu/mainMenu';
import Function from 'Component/Service/Function';
import AddCategoryComponent from './AddCategory/AddCategoryComponent';
import AllCategoryList from './CategoryList/AllCategoryListComponent';
import { useGetTenant } from 'Store/userDetails';
const ProductCategory = () => {
    const tenant = useGetTenant();
    const [catList, setCatList] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCategoryList();
    }, []);

    const fetchCategoryList = () => {
        setLoading(true);
        try {
            let extraurl = `categories?tenant=${tenant}`;

            Function.getFetch(extraurl)
                .then(response => response.json())
                .then(responseJson => {
                    console.log('get cat list', responseJson);
                    let list = responseJson._embedded.collection.map(v => {
                        return {
                            ...v,
                            createdAt: moment(v.createdAt).format('DD/MM/YYYY'),
                        };
                    });
                    setCatList(list);
                    setLoading(false);
                    if (
                        responseJson.statusCode == 409 ||
                        responseJson.statusCode == 404 ||
                        responseJson.statusCode == 400 ||
                        responseJson.statusCode == 500
                    ) {
                        alert(responseJson.message);
                    }
                })
                .catch(err => console.warn('Catch Error', err));
        } catch (error) {
            alert(error);
        }
    };

    return (
        // <MainMenu>
        <Spin spinning={loading}>
            <div>
                {catList.length > 0 ? (
                    <AllCategoryList dataSource={catList} />
                ) : (
                    <AddCategoryComponent />
                )}
            </div>
        </Spin>

        // </MainMenu>
    );
};

export default ProductCategory;
