import React, { Component } from 'react';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import 'react-google-flight-datepicker/dist/main.css';
import moment from 'moment';

export default class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
        };
    }
    onDateChange = (startDate, endDate) => {
        this.setState({
            startDate: startDate,
            endDate: endDate
        });
        this.props.getDate(this.dateRange());
    };

    dateRange = () => {
        let date = {
            startDate: moment(this.state.startDate),
            endDate: moment(this.state.endDate)
        }
        return date;
    }

    render() {

        return (
            <div>
                <RangeDatePicker
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onChange={(startDate, endDate) => this.onDateChange(startDate, endDate)}
                    minDate={new Date(2021, 0, 1)}
                    maxDate={new Date()}
                    monthFormat="MMM YYYY"
                    startDatePlaceholder="Start Date"
                    endDatePlaceholder="End Date"
                    disabled={false}
                    className="my-own-class-name"
                    startWeekDay="friday"
                />
            </div>
        );
    }
}
