import React from 'react';
import { ColorSettings, H1 } from '../../Typography';
import { ButtonXXL } from '../Button';
import i18n from '../../i18n';
import * as Constants from '../../Util/constants';
import { useHistory } from 'react-router';
import NoProductImg from '../Icon/NoProductImg';

const NoProductViewComponent = props => {
    let history = useHistory();
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <NoProductImg width={props.width} height={props.height} />
            <H1
                textTitle={i18n.i18n('no_product')}
                textStyle={{
                    color: ColorSettings.colorBlack,
                    fontWeight: 'bold',
                    lineHeight: '7rem',
                }}
            />
            <ButtonXXL
                text={i18n.i18n('new_product')}
                style={{ margin: '20px 0 0px 20px', fontSize: '24px' }}
                onClick={() => history.push(Constants.PRODUCT_ADD_ROUTE)}
            />
            <br />
        </div>
    );
};

export default NoProductViewComponent;
