import { atom, useAtomValue, useSetAtom } from 'jotai';

const orderAtom = atom([]);

export const useShowroomOrder = () => useAtomValue(orderAtom);

const writeShowroomOrderAtom = atom(null, (get, set, tempData) => {
    set(orderAtom, tempData);
});

export const useSetShowroomOrder = () => useSetAtom(writeShowroomOrderAtom);
