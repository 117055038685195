import * as React from 'react';

function SvgRefresh(props) {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0 16.0001V9.00008H7L3.783 12.2201C4.89296 13.3551 6.41246 13.9965 8 14.0001C10.5394 13.9963 12.8015 12.3943 13.648 10.0001H13.666C13.7805 9.6747 13.8672 9.34016 13.925 9.00008H15.937C15.4331 13 12.0315 16 8 16.0001H7.99C5.86876 16.0064 3.83316 15.1638 2.337 13.6601L0 16.0001ZM2.074 7.00008H0.062C0.56575 3.00169 3.965 0.00222488 7.995 4.06999e-05H8C10.1216 -0.00674779 12.1577 0.835922 13.654 2.34008L16 4.06999e-05V7.00008H9L12.222 3.78008C11.1109 2.64373 9.5893 2.00222 8 2.00008C5.46055 2.00383 3.19848 3.60586 2.352 6.00008H2.334C2.21856 6.32519 2.13189 6.65981 2.075 7.00008H2.074Z" fill="#F2F2F2" />
		</svg>
	);
}

export default SvgRefresh;