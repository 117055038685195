import * as React from 'react';

function SvgPayment(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M25 25H5C3.61929 25 2.5 23.8807 2.5 22.5V7.5C2.5 6.11929 3.61929 5 5 5H25C26.3807 5 27.5 6.11929 27.5 7.5V22.5C27.5 23.8807 26.3807 25 25 25ZM5 15V22.5H25V15H5ZM5 7.5V10H25V7.5H5ZM16.25 20H7.5V17.5H16.25V20Z"
                fill="#000"
                strokeWidth="2"
            />

        </svg>
    );
}

export default SvgPayment;
