import React, { useState, useEffect, useContext } from 'react';
import { Modal, Input, Select } from 'antd';
import i18n from 'i18n';
import { ButtonL, ButtonXS } from '../Button';
import { H3 } from 'Typography';
import { OrderContext } from 'Context/OrderContext';
import Function from '../Service/Function';
import customNotification from 'Util/Notification';
import { useGetTenant } from 'Store/userDetails';
const { Option } = Select;
const units = [
    {
        id: 'Cash',
        name: 'Cash',
    },
    {
        id: 'Cheque',
        name: 'Cheque',
    },
    {
        id: 'Bank',
        name: 'Bank',
    },
];
const ConfirmPaymentComponent = props => {
    const tenant = useGetTenant();
    const [modalVisible, setModalVisible] = useState(false);
    const [quantity, setQuantity] = useState(props.rowData.quantity);
    const [price, setPrice] = useState(props.rowData.amount);
    const [total, setTotal] = useState(0);
    const { customOrderData, setCustomOrderData } = useContext(OrderContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setQuantity(quantity);
        setPrice(price);
        setTotal(parseInt(quantity) * parseInt(price));
    }, []);

    const onConfirmOrder = e => {
        setModalVisible(true);
    };
    const onQuantityChange = e => {
        if (e !== '') {
            setQuantity(e.target.value);
            let total = parseInt(e.target.value) * parseInt(price);
            setTotal(total);
        }
        if (e.target.value === '') {
            setTotal(0);
        }
    };

    const onPriceChange = e => {
        if (e !== '') {
            setPrice(e.target.value);
            let total = parseInt(e.target.value) * parseInt(quantity);
            setTotal(total);
        }
        if (e.target.value === '') {
            setTotal(0);
        }
    };

    const onConfirmCustomOrder = () => {
        setLoading(true);
        props.rowData.accepted = true;
        props.rowData.quantity = quantity;
        props.rowData.amount = parseInt(price) * parseInt(quantity);

        let param = props.rowData;
        console.log(param);
        try {
            let extraurl = `custom_orders/${props.rowData.orderId}?tenant=${tenant}`;

            Function.postFetch(extraurl, param)
                .then(response => response.json())
                .then(responseJson => {
                    console.log('get product list', responseJson);
                    customNotification('Successful', 'Order updated successfully', 'success');
                    setLoading(false);
                    setModalVisible(false);
                    if (
                        responseJson.statusCode === 409 ||
                        responseJson.statusCode === 404 ||
                        responseJson.statusCode === 400 ||
                        responseJson.statusCode === 500
                    ) {
                        alert(responseJson.message);
                        setLoading(false);
                    }
                })
                .catch(err => console.warn('Catch Error', err));
        } catch (error) {
            alert(error);
        }
    };

    return (
        <div>
            <ButtonXS
                onClick={onConfirmOrder}
                style={{
                    backgroundColor: ' #F8786F',
                }}
                text={i18n.i18n('payment_btn')}
            />
            <Modal
                // title="Remaining Order"
                open={modalVisible}
                footer={null}
                onCancel={e => setModalVisible(false)}
                // width={1440}
            >
                <div style={{ textAlign: '', padding: '20px' }}>
                    <H3
                        // id="required"
                        textTitle={i18n.i18n('total_bill')}
                        textStyle={{
                            color: ' #000000',
                            fontWeight: 'bold',
                            fontFamily: 'Kohinoor Bangla',
                            fontStyle: 'normal',
                        }}
                    />
                    <Input
                        value={quantity}
                        disabled
                        onChange={onQuantityChange}
                        style={{
                            borderRadius: '5px',
                            height: '41px',
                            backgroundColor: 'rgba(196, 196, 196, 0.7)',
                        }}
                    />
                    <br />
                    <br />
                    <H3
                        // id="required"
                        textTitle={i18n.i18n('total_debt')}
                        textStyle={{
                            color: ' #000000',
                            fontWeight: 'bold',
                            fontFamily: 'Kohinoor Bangla',
                            fontStyle: 'normal',
                        }}
                    />
                    <Input
                        value={price}
                        disabled
                        onChange={onPriceChange}
                        style={{
                            borderRadius: '5px',
                            height: '41px',
                            backgroundColor: 'rgba(196, 196, 196, 0.7)',
                        }}
                    />
                    <br />
                    <br />
                    <H3
                        // id="required"
                        textTitle={i18n.i18n('payment_method')}
                        textStyle={{
                            color: ' #000000',
                            fontWeight: 'bold',
                            fontFamily: 'Kohinoor Bangla',
                            fontStyle: 'normal',
                        }}
                    />

                    <Select
                        showArrow
                        // style={{ width: 404 }}
                        placeholder={i18n.i18n('select_placeholder')}
                        // onChange={onActionChange}
                        mode="single"
                        size="large"
                    >
                        {units.map(v => (
                            <Option key={v.id} value={v.id}>
                                {v.name}
                            </Option>
                        ))}
                    </Select>
                    <br />
                    <br />
                    <H3
                        // id="required"
                        textTitle={i18n.i18n('payment_btn')}
                        textStyle={{
                            color: ' #000000',
                            fontWeight: 'bold',
                            fontFamily: 'Kohinoor Bangla',
                            fontStyle: 'normal',
                        }}
                    />

                    <Input
                        value={total}
                        style={{
                            borderRadius: '5px',
                            height: '41px',
                            backgroundColor: 'rgba(196, 196, 196, 0.7)',
                        }}
                    />

                    <div style={{ textAlign: 'center' }}>
                        <ButtonL
                            text={i18n.i18n('payment')}
                            style={{
                                margin: '20px',
                                backgroundColor: '#FD9A26',
                                color: 'white',
                                width: '415px',
                                height: '71px',
                                fontSize: '24px',
                            }}
                            // onClick={onConfirmCustomOrder}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ConfirmPaymentComponent;
