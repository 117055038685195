import JacobMorch01 from './JacobMorch01.webp';
import LukasKloeppel from './LukasKloeppel.webp';
import TobiasBjorkli01 from './TobiasBjorkli01.webp';
import TobiasBjorkli02 from './TobiasBjorkli02.webp';
import TobiasBjorkli03 from './TobiasBjorkli03.webp';
import TobiasBjorkli04 from './TobiasBjorkli04.webp';
import TobiasBjorkli05 from './TobiasBjorkli05.webp';
import TobiasBjorkli06 from './TobiasBjorkli06.webp';
import TobiasBjorkli07 from './TobiasBjorkli07.webp';
import TobiasBjorkli08 from './TobiasBjorkli08.webp';
import TobiasBjorkli09 from './TobiasBjorkli09.webp';
import TobiasBjorkli10 from './TobiasBjorkli10.webp';
import TobiasBjorkli11 from './TobiasBjorkli11.webp';
import TobiasBjorkli12 from './TobiasBjorkli12.webp';
import TobiasBjorkli14 from './TobiasBjorkli14.webp';
import TobiasBjorkli15 from './TobiasBjorkli15.webp';
import ValdemarasD01 from './ValdemarasD01.webp';
import MariusSchmidt01 from './MariusSchmidt01.webp';

const params = 'utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels';

const imageData = [
    {
        src: JacobMorch01,
        credit: 'Jacob Morch',
        url: `https://www.pexels.com/@jacobmorch?${params}`,
    },
    {
        src: LukasKloeppel,
        credit: 'Lukas Kloeppel',
        url: `https://www.pexels.com/@lkloeppel?${params}`,
    },
    {
        src: TobiasBjorkli01,
        credit: 'Tobias Bjørkli',
        url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
    },
    {
        src: TobiasBjorkli02,
        credit: 'Tobias Bjørkli',
        url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
    },
    {
        src: TobiasBjorkli03,
        credit: 'Tobias Bjørkli',
        url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
    },
    {
        src: TobiasBjorkli04,
        credit: 'Tobias Bjørkli',
        url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
    },
    {
        src: TobiasBjorkli05,
        credit: 'Tobias Bjørkli',
        url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
    },
    {
        src: TobiasBjorkli06,
        credit: 'Tobias Bjørkli',
        url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
    },
    {
        src: TobiasBjorkli07,
        credit: 'Tobias Bjørkli',
        url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
    },
    {
        src: TobiasBjorkli08,
        credit: 'Tobias Bjørkli',
        url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
    },
    {
        src: TobiasBjorkli09,
        credit: 'Tobias Bjørkli',
        url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
    },
    {
        src: TobiasBjorkli10,
        credit: 'Tobias Bjørkli',
        url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
    },
    {
        src: TobiasBjorkli11,
        credit: 'Tobias Bjørkli',
        url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
    },
    {
        src: TobiasBjorkli12,
        credit: 'Tobias Bjørkli',
        url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
    },

    {
        src: TobiasBjorkli14,
        credit: 'Tobias Bjørkli',
        url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
    },
    {
        src: TobiasBjorkli15,
        credit: 'Tobias Bjørkli',
        url: `https://www.pexels.com/@tobias-bjorkli-706370?${params}`,
    },
    {
        src: ValdemarasD01,
        credit: 'Valdemaras D.',
        url: `https://www.pexels.com/@valdemaras-d-784301?${params}`,
    },
    {
        src: MariusSchmidt01,
        credit: 'Marius Schmidt',
        url: `https://www.pexels.com/@marius-schmidt-600183?${params}`,
    },
];

export default imageData;
