var language = require('./Component/Language/Language.json');

const i18n = {

    i18n(key) {

        try {
            if (global.lan === "eng") return language[key].english;
            else return language[key].bangla;
        }
        catch (error) {
            // throw error
            console.warn(error);
        }
    }
}

export default i18n;