var environment = 'prod';

if (environment == 'local') {
    var baseurl = 'https://dge3lhn2opijx.cloudfront.net/api/v1/';
} else {
    // var baseurl = 'https://api.bakerz.pro/api/v1/';
    var baseurl = 'https://d2ofa2mhu4rxjj.cloudfront.net/api/v1/';
}

let getApiHeadersNode = function () {
    let jwt = null;
    let apiHeaders = new Headers({
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'application/json',
        // 'deviceId': getDeviceId()
    });
    return apiHeaders;
};

const Function = {
    checkPromiseStatus(temp) {
        console.log('temp', temp);
        if (temp.status === '455') {
            console.log('Logout Hobe');
        } else if (temp.status == '404' || temp.status == '203') {
            //  this.ExpiredJwtGetNewJwt();
            console.log('Get a New JWT');
        } else return temp;
    },

    async postFetch(extraUrl, jsonParameter) {
        let url = baseurl + extraUrl;

        let temp = await Promise.race([
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(jsonParameter),
                headers: getApiHeadersNode(),
            }),

            new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 60000)),
        ]);
        let responseStatus = this.checkPromiseStatus(temp);
        return responseStatus;
    },
    async getFetch(extraUrl) {
        let url = baseurl + extraUrl;

        let temp = await Promise.race([
            fetch(url, {
                method: 'GET',
                //  body: JSON.stringify(jsonParameter),
                headers: getApiHeadersNode(),
            }),

            new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 60000)),
        ]);
        let responseStatus = this.checkPromiseStatus(temp);
        return responseStatus;
    },
    async deleteFetch(extraUrl) {
        let url = baseurl + extraUrl;

        let temp = await Promise.race([
            fetch(url, {
                method: 'DELETE',
                //  body: JSON.stringify(jsonParameter),
                headers: getApiHeadersNode(),
            }),

            new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 60000)),
        ]);
        let responseStatus = this.checkPromiseStatus(temp);
        return responseStatus;
    },
};

export default Function;
