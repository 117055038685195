const ButtonContent = ({ leftIcon, text, rightIcon }) => {
    return (
        <div className="button-container">
            <span className="button-container-left-icon">{leftIcon}</span>
            {text}
            <div className="button-container-right-icon">{rightIcon}</div>
        </div>
    );
};

export default ButtonContent;
