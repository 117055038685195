import './AddCategory.scss';
import React, { useState, useEffect } from 'react';
import { Table, Tabs, Row, Col, Input, Select, Button, Modal, Form, Card, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import i18n from 'i18n';
import { ColorSettings, H1, H2, H3, H4, H5, TextMenu } from 'Typography';
import { ButtonL, ButtonM, ButtonS, ButtonXXS } from 'Component/Button';
import customNotification from 'Util/Notification';
import MainMenu from 'Component/Menu/mainMenu';
import { useHistory } from 'react-router';
import * as Constants from 'Util/constants';
import { InputL, InputM, InputS, InputXS } from 'Component/Input';
import { TextareaL } from 'Component/Textarea';
import productItem from 'assets/svg/productItem.svg';
import NoProductViewComponent from 'Component/Product/NoProductViewComponent';
import moment from 'moment';
import Function from 'Component/Service/Function';
import { useGetTenant } from 'Store/userDetails';
const columns = [
    {
        title: '',
        dataIndex: 'ImageURL', // this is the value that is parsed from the DB / server side
        render: theImageURL => <img alt={productItem} src={productItem} />, // 'theImageURL' is the variable you must declare in order the render the URL
    },
    {
        title: `${i18n.i18n('product_name_col')}`,
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        // defaultSortOrder: 'ascend',
    },
    {
        title: `${i18n.i18n('code_col')}`,
        dataIndex: 'code',
        // defaultSortOrder: 'descend',
        sorter: (a, b) => a.code - b.code,
    },
    {
        title: `${i18n.i18n('unit_col')}`,
        dataIndex: 'unit',
    },
    {
        title: `${i18n.i18n('created_col')}`,
        dataIndex: 'createdAt',
    },
];

const AddCategoryComponent = () => {
    const tenant = useGetTenant();
    const [form] = Form.useForm();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [productList, setProductList] = useState([]);
    const [noProduct, setNoproduct] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchProductList();
    }, []);

    const fetchProductList = () => {
        setLoading(true);

        try {
            let extraurl = `products?tenant=${tenant}`;

            Function.getFetch(extraurl)
                .then(response => response.json())
                .then(responseJson => {
                    console.log('get product list', responseJson);
                    let list = responseJson._embedded.collection
                        .map(v => {
                            return {
                                ...v,
                                price: v.price.regularPrice,
                                createdAt: moment(v.createdAt).format('DD/MM/YYYY'),
                                key: v.id,
                            };
                        })
                        .filter(el => !el.categoryId);
                    console.log(list);
                    if (list.length > 0) {
                        setProductList(list);
                        setNoproduct(false);
                    } else {
                        setNoproduct(true);
                    }
                    setLoading(false);
                    if (
                        responseJson.statusCode == 409 ||
                        responseJson.statusCode == 404 ||
                        responseJson.statusCode == 400 ||
                        responseJson.statusCode == 500
                    ) {
                        alert(responseJson.message);
                    }
                })
                .catch(err => console.warn('Catch Error', err));
        } catch (error) {
            alert(error);
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: selectedRowKeys => {
            console.log(selectedRowKeys);
            setSelectedRowKeys([...selectedRowKeys]);
        },
    };

    const onFinish = val => {
        val.productIds = selectedRowKeys;
        createCategory(val);
    };

    const createCategory = values => {
        setLoading(true);

        try {
            let extraurl = `categories?tenant=${tenant}`;
            let parameter = values;
            console.log('Param:', parameter);

            Function.postFetch(extraurl, parameter)
                .then(response => response.json())
                .then(responseJson => {
                    console.log('Create cat', responseJson);
                    customNotification('Successful', 'Category added successfully', 'success');
                    setLoading(false);
                    if (
                        responseJson.statusCode == 409 ||
                        responseJson.statusCode == 404 ||
                        responseJson.statusCode == 400 ||
                        responseJson.statusCode == 500
                    ) {
                        alert(responseJson.message);
                    }
                })
                .catch(err => console.warn('Catch Error', err));
        } catch (error) {
            alert(error);
        }
    };

    return (
        <MainMenu>
            <Spin spinning={loading}>
                <div
                    className="add-category"
                    style={{
                        padding: '50px 20px 0 20px',
                    }}
                >
                    <Form form={form} onFinish={onFinish}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <H1
                                textTitle={i18n.i18n('cat_heading')}
                                textStyle={{
                                    color: ColorSettings.colorBlack,
                                    fontWeight: 'bold',
                                    lineHeight: '5rem',
                                }}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <ButtonS
                                    text={i18n.i18n('discard_btn')}
                                    style={{
                                        margin: '20px 0 0px 20px',
                                        backgroundColor: '#828282',
                                        fontSize: '19px',
                                    }}
                                />
                                <ButtonS
                                    text={i18n.i18n('save_btn')}
                                    style={{ margin: '20px 0 0px 20px', fontSize: '19px' }}
                                    htmlType="submit"
                                />
                            </div>
                        </div>
                        <br />
                        <Row>
                            <Col lg={{ span: 16, offset: 0 }}>
                                <Card className="basic-details">
                                    <H4
                                        id="required"
                                        textTitle={i18n.i18n('title_input')}
                                        textStyle={{ color: '#3B2D2D' }}
                                    />
                                    <Form.Item
                                        name="name"
                                        rules={[{ required: true, message: 'Please input title!' }]}
                                    >
                                        <InputL placeholder="e.g Dry Cake" />
                                    </Form.Item>
                                    <H4
                                        // id="required"
                                        textTitle={i18n.i18n('description')}
                                        textStyle={{ color: '#3B2D2D' }}
                                    />
                                    <Form.Item
                                        name="description"
                                        // rules={[{ required: true, message: 'Please input description!' }]}
                                    >
                                        <TextareaL
                                            placeholder={i18n.i18n('Placeholder_Textarea')}
                                        />
                                    </Form.Item>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                    <br />
                    <br />
                    {!noProduct ? (
                        <Table
                            className="product-list-table"
                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={productList}
                        />
                    ) : (
                        <NoProductViewComponent width="366" height="304" />
                    )}
                </div>
            </Spin>
        </MainMenu>
    );
};

export default AddCategoryComponent;
