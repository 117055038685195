// import './FactoryOrder.scss';
import React, { useState, useEffect } from 'react';
import { Table, Tabs, Row, Col, Input, Select, Button, Modal, Tooltip, Spin, Empty } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import moment from 'moment';
import i18n from 'i18n';
import { useHistory, useLocation } from 'react-router-dom';
import {
    FACTORY_SALES_INVOICE_ROUTE,
    numberWithCommas,
    SHOWROOM_INVOICE_ROUTE,
} from 'Util/constants';
import Function from 'Component/Service/Function';
import NoOrdersComponent from 'Component/Orders/NoOrdersComponent';
import { ColorSettings, H1 } from 'Typography';
import { ButtonXXS } from '../Button';
import { useGetTenant } from 'Store/userDetails';
const FactorySalesInvoice = () => {
    const tenant = useGetTenant();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setCol] = useState([]);
    const [catList, setCatList] = useState([]);
    const url = useLocation();
    const searchParams = new URLSearchParams(url.search);
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');

    console.log('URL:==>', startDate, endDate);

    useEffect(() => {
        fetchProductList(startDate, endDate);
    }, []);

    const fetchProductList = (startDate, endDate) => {
        setLoading(true);

        try {
            let extraurl = `factory_orders?tenant=${tenant}&startDate=${startDate}&endDate=${endDate}`;
            console.log('extraurl', extraurl);
            Function.getFetch(extraurl)
                .then(response => response.json())
                .then(responseJson => {
                    console.log('get order list', responseJson);
                    if (responseJson.total === 0) {
                        setLoading(false);
                        return;
                    }
                    let list = responseJson?._embedded?.collection?.map((v, i) => {
                        return {
                            ...v,
                            key: i + 1,
                            Bill: v.amount,
                            'Showroom Name': v.userName,
                        };
                    });
                    setData(list);

                    let cols = list[0];
                    let name = Object.keys(cols).filter(v => v == 'Showroom Name');
                    let others = Object.keys(cols).filter(v => v == 'Bill');
                    console.log(name, others);

                    let all_col = name.concat(responseJson.categoryList, others);
                    console.log(all_col);

                    const col = all_col.map(v => {
                        return {
                            title: v,
                            dataIndex: v,
                        };
                    });
                    console.log(col);
                    setCol(col);
                    setCatList([...responseJson.categoryList, 'Bill']);
                    setLoading(false);
                    if (
                        responseJson.statusCode == 409 ||
                        responseJson.statusCode == 404 ||
                        responseJson.statusCode == 400 ||
                        responseJson.statusCode == 500
                    ) {
                        alert(responseJson.message);
                        setLoading(false);
                    }
                })
                .catch(err => console.warn('Catch Error', err));
        } catch (error) {
            alert(error);
        }
    };
    return (
        <div className="showroom-invoice">
            <div className="print-btn" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonXXS
                    leftIcon={<PrinterOutlined />}
                    onClick={e => window.print()}
                    text={i18n.i18n('print_btn')}
                    style={{
                        backgroundColor: '#4890FC',
                        width: '161px',
                        fontSize: '19px',
                        borderRadius: '5px',
                        boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.25)',
                    }}
                />
            </div>
            <div style={{ textAlign: 'center' }}>
                <H1
                    textTitle={i18n.i18n('invoice_title')}
                    textStyle={{
                        color: ColorSettings.colorBlack,
                        fontWeight: 'bold',
                        lineHeight: '5rem',
                    }}
                />
                <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                    Sales Outstanding Report
                </p>
            </div>
            <br />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                    {moment().format('YYYY-MM-DD')}
                </p>
            </div>
            <br />
            <Table
                className="showroom-order-table"
                columns={columns}
                dataSource={data}
                bordered
                pagination={false}
                summary={pageData => {
                    console.log(pageData);
                    let summaryRowArr = [];

                    for (const cat of catList) {
                        let totalAmount = 0;
                        pageData.forEach(row => {
                            totalAmount += row[cat];
                        });

                        summaryRowArr.push(<Table.Summary.Cell>{totalAmount}</Table.Summary.Cell>);
                    }

                    console.log('sumary', summaryRowArr);

                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                {/* <Table.Summary.Cell></Table.Summary.Cell> */}
                            </Table.Summary.Row>

                            <Table.Summary.Row>
                                <Table.Summary.Cell>Total</Table.Summary.Cell>
                                {summaryRowArr}
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />

            <br />
        </div>
    );
};

export default FactorySalesInvoice;
