import React, { useState } from 'react';
import { MainMenu } from '../../Component/index';
import { Skeleton, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import DatePicker from '../../Component/Date Picker/dateRangePicker';
import SkeletonView from '../../Component/Skeleton/skeleton';

const { Search } = Input;

const searchRenderView = () => {
    return (
        <div style={{ marginTop: 15 }}>
            <Space direction="vertical">
                <Search
                    placeholder="Search"
                    onSearch={e => console.log(e)}
                    style={{ width: 817 }}
                    allowClear
                    enterButton
                />
            </Space>
        </div>
    );
};
const Dashboard = props => {
    const [loading, setLoading] = useState(true);

    return (
        <MainMenu>
            <div style={{ width: 400, marginTop: 30 }}>
                <DatePicker getDate={e => this.onDateChange(e)} />
            </div>
            {searchRenderView()}
            {loading ? <SkeletonView /> : null}
        </MainMenu>
    );
};

export default Dashboard;
