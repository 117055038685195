import './textareaStyle.css';

import React from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

const TextareaL = props => {
    return (
        <div>
            <TextArea
                className="textAreaL"
                placeholder={props.placeholder}
                style={{ ...props.style }}
                onChange= { props.onChange }
            />
        </div>
    );
};

export { TextareaL };
