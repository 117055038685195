import './InputStyle.css';

import PropTypes from 'prop-types';
import { Input } from 'antd';

const InputL = ({ placeholder, style, leftIcon, rightIcon, onChange, disable }) => {
    return (
        <div className="input">
            <Input
                placeholder={placeholder}
                className="InputL"
                prefix={leftIcon}
                suffix={rightIcon}
                style={{ ...style }}
                onChange={onChange}
                disabled={disable}
            />
        </div>
    );
};

InputL.defaultProps = {
    placeholder: 'type here..',
};

InputL.propType = {
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export { InputL };
