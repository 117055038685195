import './ShowroomOrderStyle.scss';
import React, { useState, useEffect } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, Spin, Button } from 'antd';
import MainMenu from 'Component/Menu/mainMenu';
import { ColorSettings, H1 } from 'Typography';
import i18n from 'i18n';
import Function from 'Component/Service/Function';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import customNotification from 'Util/Notification';
import { useGetTenant } from 'Store/userDetails';
// for (let i = 0; i < 100; i++) {
// 	originData.push({
// 		key: i.toString(),
// 		name: `Edrward ${i}`,
// 		age: 32,
// 		address: `London Park no. ${i}`,
// 	});
// }

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const EditShowRoomOrderComponent = () => {
    const tenant = useGetTenant();
    const [form] = Form.useForm();
    const [orderInfo, setOrderInfo] = useState({});
    const [editingKey, setEditingKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    let { id } = useParams();

    useEffect(() => {
        fetchProductList();
    }, []);

    const fetchProductList = () => {
        setLoading(true);
        try {
            let extraurl = `orders/${id}?tenant=${tenant}`;

            Function.getFetch(extraurl)
                .then(response => response.json())
                .then(responseJson => {
                    console.log('get order list', responseJson);
                    setOrderInfo(responseJson);
                    let list = responseJson.products.map((v, i) => {
                        return {
                            ...v,
                            key: i + 1,
                        };
                    });
                    setData(list);
                    setLoading(false);
                    if (
                        responseJson.statusCode == 409 ||
                        responseJson.statusCode == 404 ||
                        responseJson.statusCode == 400 ||
                        responseJson.statusCode == 500
                    ) {
                        alert(responseJson.message);
                        setLoading(false);
                    }
                })
                .catch(err => console.warn('Catch Error', err));
        } catch (error) {
            alert(error);
        }
    };

    const isEditing = record => record.key === editingKey;

    const edit = record => {
        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async key => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex(item => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
            // for (const cat of orderInfo.categories) {
            // 	console.log(cat);
            // 	let sum = 0;
            // 	for (const product of newData) {
            // 		console.log(product);
            // 		if (product.categoryIdCategoryId === cat.id) {
            // 			console.log(product.categoryIdCategoryId, cat.id);
            // 			sum = sum + (product.price * parseInt(product.quantity))
            // 		} else {

            // 		}
            // 	}
            // 	console.log(sum);
            // 	cat.price = sum;
            // }
            orderInfo.products = newData;
            console.log('Order Info', orderInfo);
            setLoading(true);

            try {
                let extraurl = `orders/${id}?tenant=${tenant}`;
                let param = orderInfo;
                Function.postFetch(extraurl, param)
                    .then(response => response.json())
                    .then(responseJson => {
                        console.log('get order list', responseJson);
                        customNotification(
                            'Successful',
                            'Product quantity updated successfully',
                            'success',
                        );
                        setLoading(false);
                        if (
                            responseJson.statusCode == 409 ||
                            responseJson.statusCode == 404 ||
                            responseJson.statusCode == 400 ||
                            responseJson.statusCode == 500
                        ) {
                            alert(responseJson.message);
                            setLoading(false);
                        }
                    })
                    .catch(err => console.warn('Catch Error', err));
            } catch (error) {
                setLoading(false);
                alert(error);
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };
    const columns = [
        {
            title: 'Product Name',
            dataIndex: 'name',
            width: '25%',
            // editable: true,
        },
        {
            title: 'Product Code',
            dataIndex: 'code',
            width: '15%',
            // editable: true,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: '15%',
            editable: true,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            width: '10%',
            // editable: true,
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
            width: '10%',
            // editable: true,
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            width: '40%',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.key)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    // <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                    // 	Edit
                    // </Typography.Link>
                    <Button
                        onClick={e => {
                            edit(record);
                        }}
                        disabled={editingKey !== ''}
                        style={{
                            backgroundColor: '#F8786F',
                            borderRadius: '5px',
                            color: 'white',
                            fontSize: '13px',
                            width: '71px',
                            lineHeight: '19px',
                            boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.25)',
                        }}
                    >
                        {i18n.i18n('edit_btn')}
                    </Button>
                );
            },
        },
    ];
    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: record => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    return (
        <MainMenu>
            <Spin spinning={loading}>
                <div
                    className="showroom-order"
                    style={{
                        padding: '50px 20px 0 20px',
                    }}
                >
                    <H1
                        textTitle={i18n.i18n('edit_showroom')}
                        textStyle={{
                            color: ColorSettings.colorBlack,
                            fontWeight: 'bold',
                            lineHeight: '5rem',
                        }}
                    />
                    <Form form={form} component={false}>
                        <Table
                            className="showroom-order-table"
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            bordered
                            dataSource={data}
                            columns={mergedColumns}
                            rowClassName="editable-row"
                            pagination={false}
                        />
                    </Form>
                </div>
            </Spin>
        </MainMenu>
    );
};

export default EditShowRoomOrderComponent;
