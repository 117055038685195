import { atom, useAtomValue, useSetAtom } from 'jotai';
const localTenant = localStorage.getItem('tenant');

const tenantAtom = atom(localTenant ?? '');

export const useGetTenant = () => useAtomValue(tenantAtom);

const writeTenantAtom = atom(null, (get, set, tenant) => {
    set(tenantAtom, tenant);
});

export const useSetTenant = () => useSetAtom(writeTenantAtom);
