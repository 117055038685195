import React, { useState, createContext } from "react";

export const OrderContext = createContext();

export const OrderContextProvider = (props) => {
    const [customOrderData, setCustomOrderData] = useState([]);
    return (
        <OrderContext.Provider
            value={{ customOrderData, setCustomOrderData }}
        >
            {props.children}
        </OrderContext.Provider>
    );
};
