import './style.css';

import AccessAlarm from '@material-ui/icons/AccessAlarm';
import DeleteIcon from '@material-ui/icons/Delete';
import { Input, Modal, Select, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';

import i18n from '../i18n';
import { ColorSettings, H1, H2, H3, H4, H5, TextMenu } from '../Typography';
import {
    ButtonL,
    ButtonM,
    ButtonS,
    ButtonXL,
    ButtonXS,
    ButtonXXL,
    ButtonXXS,
    FlatButton,
} from './Button';
import DatePicker from './Date Picker/dateRangePicker';
import MainMenu from './Menu/mainMenu';
import Function from './Service/Function';
import { TextareaL } from './Textarea';

//import {SalesGraph} from "./Image";
// import MainMenu from './Menu/mainMenu';
const { Option } = Select;

const dataSource = [
    {
        key: '1',
        name: 'Mike',
        age: 32,
        address: '10 Downing Street',
    },
    {
        key: '2',
        name: 'John',
        age: 42,
        address: '10 Downing Street',
    },
];

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    },
];

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: {
                startDate: moment().format('DD MMMM YYYY'),
                endDate: moment().format('DD MMMM YYYY'),
            },
            optionArr: [
                {
                    id: 1,
                    name: 'Cakes',
                },
                {
                    id: 2,
                    name: 'Sweets',
                },
                {
                    id: 3,
                    name: 'Fastfood',
                },
            ],
            isConfirmModalVisible: false,
            isRemainingModalVisible: false,
        };
        global.lan = 'bng'

    }

    componentDidMount() {

    }

    dateRangePicker = date => {
        this.setState({
            date: date,
        });
    };
    handleChange = value => {
        console.log(`selected ${value}`);
    };

    showModal = () => {
        this.setState({
            isConfirmModalVisible: true,
        });
    };

    showRemainingOrderModal = () => {
        this.setState({
            isRemainingModalVisible: true,
        });
    };

    textView = () => {
        return (
            <div>
                <H1
                    textTitle="H1 is about 36 px"
                    textStyle={{ color: ColorSettings.colorPrimary }}
                />
                <H2 textTitle="H2 is about 24 px" textStyle={{ color: ColorSettings.colorTheme }} />
                <H3 textTitle="H3 is about 19 px" textStyle={{ color: ColorSettings.colorGreen }} />
                <H4 textTitle="H4 is about 16 px" textStyle={{ color: ColorSettings.colorGreen }} />
                <H5
                    textTitle="H5 is about 13 px"
                    textStyle={{ color: ColorSettings.colorGreenDark }}
                />
            </div>
        );
    };

    homeView = () => {
        return (
            <div className="App">
                <header className="App-header">
                    <div className="Typography">
                        <h1 style={{ color: ColorSettings.colorTheme }}>Text</h1>
                        {this.textView()}
                        <TextMenu
                            textTitle={i18n.i18n('Menu_Text')}
                            textStyle={{ color: ColorSettings.colorTheme }}
                        />
                        <br />
                        <H1 textTitle="Button" textStyle={{ color: ColorSettings.colorTheme }} />
                        <FlatButton
                            text={i18n.i18n('Button_Text')}
                            leftIcon={<DeleteIcon />}
                            style={{ margin: '20px' }}
                        />

                        <ButtonXXL text={i18n.i18n('Button_Text')} leftIcon={<DeleteIcon />} />

                        <ButtonXL
                            text={i18n.i18n('Button_Text')}
                            style={{ margin: '20px' }}
                            rightIcon={<AccessAlarm />}
                        />
                        <ButtonL text={i18n.i18n('Button_Text')} style={{ margin: '20px' }} />
                        <ButtonM
                            text={i18n.i18n('Button_Text')}
                            style={{ margin: '20px' }}
                            rightIcon={<AccessAlarm />}
                        />
                        <ButtonS text={i18n.i18n('Button_Text')} style={{ margin: '20px' }} />
                        <ButtonXS
                            text={i18n.i18n('Button_Text')}
                            style={{ margin: '20px' }}
                            rightIcon={<AccessAlarm />}
                        />
                        <ButtonXXS text={i18n.i18n('Button_Text')} style={{ margin: '20px' }} />
                        <br />
                        <H1
                            textTitle="Date Range"
                            textStyle={{ color: ColorSettings.colorPrimary }}
                        />
                        <DatePicker getDate={e => this.dateRangePicker(e)} />
                        <div>
                            <H3
                                textTitle={'Start Date: ' + this.state.date.startDate}
                                textStyle={{ color: ColorSettings.colorBlack }}
                            />
                            <H3
                                textTitle={'End Date: ' + this.state.date.endDate}
                                textStyle={{ color: ColorSettings.colorGreen }}
                            />
                        </div>
                        <br />
                        <H1 textTitle="TextArea" textStyle={{ color: ColorSettings.colorTheme }} />
                        <TextareaL
                            style={{ margin: '20px' }}
                            placeholder={i18n.i18n('Placeholder_Textarea')}
                        />
                        <H1 textTitle="DropDown" textStyle={{ color: ColorSettings.colorTheme }} />
                        <Select
                            showSearch
                            showArrow
                            allowClear
                            style={{ width: 214 }}
                            placeholder="Select an Item"
                            onChange={this.onChange}
                            mode="single"
                            size="middle"
                        >
                            {this.state.optionArr.map(v => (
                                <Option value={v.id}>{v.name}</Option>
                            ))}
                        </Select>
                        <br />
                        <br />
                        <Select
                            showSearch
                            showArrow
                            allowClear
                            style={{ width: 529 }}
                            placeholder="Select Multiple Item"
                            onChange={this.onChange}
                            mode="multiple"
                            size="large"
                        >
                            {this.state.optionArr.map(v => (
                                <Option key={v.id} value={v.id}>
                                    {v.name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <br />
                    <ButtonXS text={i18n.i18n('ConfirmModal_Text')} onClick={this.showModal} />
                    <Modal
                        title="Confirm Modal"
                        visible={this.state.isConfirmModalVisible}
                        footer={null}
                        onCancel={e => this.setState({ isConfirmModalVisible: false })}
                        width={642}
                    >
                        <div style={{ textAlign: 'center' }}>
                            <Input style={{ width: '50%' }} />
                            <br />
                            <br />
                            <Input style={{ width: '50%' }} />
                            <br />
                            <ButtonL
                                text={i18n.i18n('Button_Text')}
                                style={{
                                    margin: '20px',
                                    backgroundColor: '#FD9A26',
                                    color: 'white',
                                }}
                            />
                        </div>
                    </Modal>
                    <ButtonXS
                        text={i18n.i18n('RemainingModal_Text')}
                        onClick={this.showRemainingOrderModal}
                    />
                    <Modal
                        title="Remaining Order"
                        visible={this.state.isRemainingModalVisible}
                        footer={null}
                        onCancel={e => this.setState({ isRemainingModalVisible: false })}
                        width={1440}
                    >
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                            <Table dataSource={dataSource} columns={columns} />
                            <br />
                            <ButtonL
                                text={i18n.i18n('Button_Text')}
                                style={{
                                    margin: '20px',
                                    backgroundColor: '#FD9A26',
                                    color: 'white',
                                }}
                            />
                        </div>
                    </Modal>
                    <br />
                </header>
            </div>
        );
    };

    render() {
        return <MainMenu>{this.homeView()}</MainMenu>;
    }
}
