import './AddProductForm.scss';
import React, { useState, useEffect } from 'react';
import {
    Table,
    Tabs,
    Row,
    Col,
    Input,
    Select,
    Button,
    Modal,
    Form,
    Card,
    Upload,
    Spin,
    InputNumber,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import i18n from 'i18n';
import { ColorSettings, H1, H2, H3, H4, H5, TextMenu } from 'Typography';
import { ButtonL, ButtonM, ButtonS, ButtonXXS } from 'Component/Button';
import uploadimg from 'assets/svg/uploadimg.svg';
import customNotification from 'Util/Notification';
import MainMenu from 'Component/Menu/mainMenu';
import { useHistory } from 'react-router';
import * as Constants from 'Util/constants';
import { InputL, InputM, InputS, InputXS } from 'Component/Input';
import { TextareaL } from 'Component/Textarea';
import Function from 'Component/Service/Function';
import { useGetTenant } from 'Store/userDetails';
const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;
const { confirm, success } = Modal;
const { Dragger } = Upload;

global.lan = 'bng';

const units = [
    {
        id: 'KG',
        name: 'KG',
    },
    {
        id: 'Piece',
        name: 'Piece',
    },
];

const AddproductForm = () => {
    const tenant = useGetTenant();
    const [form] = Form.useForm();
    const [differentPrice, setDifferentPrice] = useState(false);
    const [differentPriceBtn, setDifferentPriceBtn] = useState(true);
    const [allPriceBtn, setAllPriceBtn] = useState(false);
    const [disable, setDisable] = useState(false);
    const [catArr, setCatArr] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = 'Bakers: Add Prouct';
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchCategoryList();
    }, []);

    const fetchCategoryList = () => {
        try {
            let extraurl = `categories?tenant=${tenant}`;

            Function.getFetch(extraurl)
                .then(response => response.json())
                .then(responseJson => {
                    console.log('get cat list', responseJson);
                    setCatArr(responseJson._embedded.collection);
                    if (
                        responseJson.statusCode == 409 ||
                        responseJson.statusCode == 404 ||
                        responseJson.statusCode == 400 ||
                        responseJson.statusCode == 500
                    ) {
                        alert(responseJson.message);
                    }
                })
                .catch(err => console.warn('Catch Error', err));
        } catch (error) {
            alert(error);
        }
    };

    const onSetDifferentPrice = () => {
        form.setFieldsValue({ price_for_all: null });
        setDifferentPrice(true);
        setDifferentPriceBtn(false);
        setAllPriceBtn(true);
        setDisable(true);
    };

    const onSetAllPrice = () => {
        setDifferentPriceBtn(true);
        setAllPriceBtn(false);
        setDisable(false);
        form.setFieldsValue({
            showroom_price: null,
            dealer_price: null,
            salesman_price: null,
            corporate_price: null,
        });
        setDifferentPrice(false);
    };

    const onFinish = val => {
        val.status = 'available';
        if (val.price_for_all != null) {
            val.samePriceForAll = true;
            val.price = { regularPrice: val.price_for_all };
        } else {
            val.samePriceForAll = false;
            val.price = {
                salesmanPrice: val.salesman_price,
                showroomPrice: val.showroom_price,
                dealer_price: val.dealer_price,
                corporatePrice: val.corporate_price,
            };
        }

        createProduct(val);
    };

    const createProduct = values => {
        setLoading(true);

        try {
            let extraurl = `products?tenant=${tenant}`;
            let parameter = values;
            console.log('Param:', parameter);

            Function.postFetch(extraurl, parameter)
                .then(response => response.json())
                .then(responseJson => {
                    console.log('Create product', responseJson);
                    customNotification('Successful', 'Product added successfully', 'success');
                    setLoading(false);
                    form.resetFields();
                    if (
                        responseJson.statusCode == 409 ||
                        responseJson.statusCode == 404 ||
                        responseJson.statusCode == 400 ||
                        responseJson.statusCode == 500
                    ) {
                        alert(responseJson.message);
                    }
                })
                .catch(err => console.warn('Catch Error', err));
        } catch (error) {
            alert(error);
        }
    };
    return (
        <MainMenu>
            <Spin spinning={loading}>
                <div
                    className="add-product"
                    style={{
                        padding: '50px 20px 0 20px',
                    }}
                >
                    <Form form={form} onFinish={onFinish}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <H1
                                textTitle={i18n.i18n('add_product')}
                                textStyle={{
                                    color: ColorSettings.colorBlack,
                                    fontWeight: 'bold',
                                    lineHeight: '5rem',
                                }}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <ButtonS
                                    text={i18n.i18n('discard_btn')}
                                    style={{
                                        margin: '20px 0 0px 20px',
                                        backgroundColor: '#828282',
                                        fontSize: '19px',
                                    }}
                                />
                                <ButtonS
                                    text={i18n.i18n('save_btn')}
                                    style={{ margin: '20px 0 0px 20px', fontSize: '19px' }}
                                    htmlType="submit"
                                />
                            </div>
                        </div>
                        <br />
                        <Row>
                            <Col lg={{ span: 16, offset: 0 }}>
                                <Card className="basic-details">
                                    <H4
                                        id="required"
                                        textTitle={i18n.i18n('title_input')}
                                        textStyle={{ color: '#3B2D2D' }}
                                    />
                                    <Form.Item
                                        name="name"
                                        rules={[{ required: true, message: 'Please input title!' }]}
                                    >
                                        <InputL placeholder="e.g Dry Cake" />
                                    </Form.Item>
                                    <H4
                                        id="required"
                                        textTitle={i18n.i18n('title_bng_input')}
                                        textStyle={{ color: '#3B2D2D' }}
                                    />
                                    <Form.Item
                                        name="bdName"
                                        rules={[{ required: true, message: 'Please input title!' }]}
                                    >
                                        <InputL placeholder="e.g বিস্কুট" />
                                    </Form.Item>
                                    <H4
                                        id="required"
                                        textTitle={i18n.i18n('code_col')}
                                        textStyle={{ color: '#3B2D2D' }}
                                    />
                                    <Form.Item
                                        name="code"
                                        rules={[{ required: true, message: 'Please input code!' }]}
                                    >
                                        <InputNumber
                                            style={{ width: '100%' }}
                                            min={0}
                                            className="input_price"
                                            placeholder="e.g 105"
                                        />
                                    </Form.Item>
                                    <H4
                                        id="required"
                                        textTitle={i18n.i18n('description')}
                                        textStyle={{ color: '#3B2D2D' }}
                                    />
                                    <Form.Item
                                        name="description"
                                        rules={[{ required: true, message: 'Please input!' }]}
                                        // rules={[{ required: true, message: 'Please input description!' }]}
                                    >
                                        <TextareaL
                                            placeholder={i18n.i18n('Placeholder_Textarea')}
                                        />
                                    </Form.Item>
                                </Card>
                            </Col>
                            <Col lg={{ span: 7, offset: 1 }}>
                                <Card className="org" title={i18n.i18n('org_title')}>
                                    <H4
                                        // id="required"
                                        textTitle={i18n.i18n('product_type')}
                                        textStyle={{ color: '#3B2D2D' }}
                                    />
                                    <Form.Item
                                        name="categoryId"
                                        // rules={[{ required: true, message: 'Please input description!' }]}
                                    >
                                        <Select
                                            showArrow
                                            allowClear
                                            // style={{ width: 404 }}
                                            placeholder={i18n.i18n('select_placeholder')}
                                            // onChange={onActionChange}
                                            mode="single"
                                            size="large"
                                        >
                                            {catArr.map(v => (
                                                <Option key={v.id} value={v.id}>
                                                    {v.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Card>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col lg={{ span: 16, offset: 0 }}>
                                <Card className="images" title={i18n.i18n('Img_title')}>
                                    <Dragger className="upload-list-inline">
                                        <p className="ant-upload-drag-icon">
                                            <img src={uploadimg}></img>
                                        </p>
                                        <p className="ant-upload-text">
                                            {i18n.i18n('upload_image_hint')}
                                        </p>
                                        <p className="ant-upload-hint"></p>
                                        <ButtonM
                                            text={i18n.i18n('upload_img_btn')}
                                            style={{
                                                margin: '0px 0 0px 0px',
                                                width: '272px',
                                                boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.25)',
                                                fontSize: '19px',
                                            }}
                                        />
                                    </Dragger>
                                </Card>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col lg={{ span: 16, offset: 0 }}>
                                <Card className="pricing" title={i18n.i18n('price_title')}>
                                    <Row>
                                        <Col lg={{ span: 10, offset: 0 }}>
                                            <H4
                                                // id="required"
                                                textTitle={i18n.i18n('price_all_title')}
                                                textStyle={{ color: '#3B2D2D' }}
                                            />
                                            <Form.Item
                                                name="price_for_all"
                                                // rules={[{ required: true, message: 'Please input description!' }]}
                                            >
                                                <Input
                                                    className="input_price"
                                                    placeholder="45.00"
                                                    disabled={disable}
                                                />
                                            </Form.Item>
                                            {allPriceBtn && (
                                                <ButtonM
                                                    text={i18n.i18n('set_price_all_btn')}
                                                    style={{
                                                        margin: '10px 0 0px 0px',
                                                        width: '272px',
                                                        boxShadow:
                                                            '4px 4px 20px rgba(0, 0, 0, 0.25)',
                                                        backgroundColor: '#F8786F',
                                                        fontSize: '19px',
                                                    }}
                                                    onClick={onSetAllPrice}
                                                />
                                            )}
                                            {differentPriceBtn && (
                                                <ButtonM
                                                    text={i18n.i18n('set_price_diff_btn')}
                                                    style={{
                                                        margin: '10px 0 0px 0px',
                                                        width: '272px',
                                                        boxShadow:
                                                            '4px 4px 20px rgba(0, 0, 0, 0.25)',
                                                        fontSize: '19px',
                                                    }}
                                                    onClick={onSetDifferentPrice}
                                                />
                                            )}
                                            <br />
                                            {differentPrice && (
                                                <div>
                                                    <H4
                                                        // id="required"
                                                        textTitle={i18n.i18n('showroom_price')}
                                                        textStyle={{ color: '#3B2D2D' }}
                                                    />
                                                    <Form.Item
                                                        name="showroom_price"
                                                        // rules={[{ required: true, message: 'Please input description!' }]}
                                                    >
                                                        <InputS placeholder="45.00" />
                                                    </Form.Item>
                                                    <H4
                                                        // id="required"
                                                        textTitle={i18n.i18n('dealer_price')}
                                                        textStyle={{ color: '#3B2D2D' }}
                                                    />
                                                    <Form.Item
                                                        name="dealer_price"
                                                        // rules={[{ required: true, message: 'Please input description!' }]}
                                                    >
                                                        <InputS placeholder="45.00" />
                                                    </Form.Item>
                                                    <H4
                                                        // id="required"
                                                        textTitle={i18n.i18n('salesman_price')}
                                                        textStyle={{ color: '#3B2D2D' }}
                                                    />
                                                    <Form.Item
                                                        name="salesman_price"
                                                        // rules={[{ required: true, message: 'Please input description!' }]}
                                                    >
                                                        <InputS placeholder="45.00" />
                                                    </Form.Item>
                                                    <H4
                                                        // id="required"
                                                        textTitle={i18n.i18n('corporate_price')}
                                                        textStyle={{ color: '#3B2D2D' }}
                                                    />
                                                    <Form.Item
                                                        name="corporate_price"
                                                        // rules={[{ required: true, message: 'Please input description!' }]}
                                                    >
                                                        <InputS placeholder="45.00" />
                                                    </Form.Item>
                                                </div>
                                            )}
                                        </Col>
                                        <Col lg={{ span: 10, offset: 1 }}>
                                            <H4
                                                id="required"
                                                textTitle={i18n.i18n('unit_col')}
                                                textStyle={{ color: '#3B2D2D' }}
                                            />
                                            <Form.Item
                                                name="unit"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input unit!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showArrow
                                                    allowClear
                                                    // style={{ width: 404 }}
                                                    placeholder={i18n.i18n('select_placeholder')}
                                                    // onChange={onActionChange}
                                                    mode="single"
                                                    size="large"
                                                >
                                                    {units.map(v => (
                                                        <Option key={v.id} value={v.id}>
                                                            {v.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <br />
                        <br />
                    </Form>
                </div>
            </Spin>
        </MainMenu>
    );
};

export default AddproductForm;
