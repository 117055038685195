import React, { useState, useEffect, useContext } from 'react';
import { Table, Modal, Form, Input, Button, Spin } from 'antd';
import { RiDeleteBinLine, RiCheckFill } from 'react-icons/ri';
import i18n from 'i18n';
import { ButtonL, ButtonXXXS } from '../Button';
import { H3 } from 'Typography';
import { OrderContext } from 'Context/OrderContext';
import Function from '../Service/Function';
import customNotification from 'Util/Notification';
import { useGetTenant } from 'Store/userDetails';

const ConfirmCustomOrderComponent = props => {
    const tenant = useGetTenant();
    const [modalVisible, setModalVisible] = useState(false);
    const [quantity, setQuantity] = useState(props.rowData.quantity);
    const [price, setPrice] = useState(props.rowData.amount);
    const [total, setTotal] = useState(0);
    const { customOrderData, setCustomOrderData } = useContext(OrderContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setQuantity(quantity);
        setPrice(price);
        setTotal(parseInt(quantity) * parseInt(price));
    }, []);

    const onConfirmOrder = e => {
        setModalVisible(true);
    };
    const onQuantityChange = e => {
        if (e != '') {
            setQuantity(e.target.value);
            let total = parseInt(e.target.value) * parseInt(price);
            setTotal(total);
        }
        if (e.target.value == '') {
            setTotal(0);
        }
    };

    const onPriceChange = e => {
        if (e != '') {
            setPrice(e.target.value);
            let total = parseInt(e.target.value) * parseInt(quantity);
            setTotal(total);
        }
        if (e.target.value == '') {
            setTotal(0);
        }
    };

    const onConfirmCustomOrder = () => {
        setLoading(true);
        props.rowData.accepted = true;
        props.rowData.quantity = quantity;
        props.rowData.amount = parseInt(price) * parseInt(quantity);

        let param = props.rowData;
        console.log(param);
        try {
            let extraurl = `custom_orders/${props.rowData.orderId}?tenant=${tenant}`;

            Function.postFetch(extraurl, param)
                .then(response => response.json())
                .then(responseJson => {
                    console.log('get product list', responseJson);
                    customNotification('Successful', 'Order updated successfully', 'success');
                    setLoading(false);
                    setModalVisible(false);
                    if (
                        responseJson.statusCode == 409 ||
                        responseJson.statusCode == 404 ||
                        responseJson.statusCode == 400 ||
                        responseJson.statusCode == 500
                    ) {
                        alert(responseJson.message);
                        setLoading(false);
                    }
                })
                .catch(err => console.warn('Catch Error', err));
        } catch (error) {
            alert(error);
        }
    };

    return (
        <div>
            <Spin spinning={loading}>
                <ButtonXXXS
                    onClick={onConfirmOrder}
                    style={{
                        backgroundColor: ' #4890FC',
                    }}
                    leftIcon={<RiCheckFill />}
                    text={i18n.i18n('accept_btn')}
                />
                <Modal
                    // title="Remaining Order"
                    open={modalVisible}
                    footer={null}
                    onCancel={e => setModalVisible(false)}
                    // width={1440}
                >
                    <div style={{ textAlign: '', padding: '20px' }}>
                        <H3
                            // id="required"
                            textTitle={i18n.i18n('total_qty_title')}
                            textStyle={{
                                color: ' #000000',
                                fontWeight: 'bold',
                                fontFamily: 'Kohinoor Bangla',
                                fontStyle: 'normal',
                            }}
                        />
                        <Input
                            value={quantity}
                            onChange={onQuantityChange}
                            style={{
                                borderRadius: '5px',
                                height: '41px',
                                backgroundColor: 'rgba(196, 196, 196, 0.7)',
                            }}
                        />
                        <br />
                        <H3
                            // id="required"
                            textTitle={i18n.i18n('price_perunit_title')}
                            textStyle={{
                                color: ' #000000',
                                fontWeight: 'bold',
                                fontFamily: 'Kohinoor Bangla',
                                fontStyle: 'normal',
                            }}
                        />
                        <Input
                            value={price}
                            onChange={onPriceChange}
                            style={{
                                borderRadius: '5px',
                                height: '41px',
                                backgroundColor: 'rgba(196, 196, 196, 0.7)',
                            }}
                        />
                        <br />
                        <H3
                            // id="required"
                            textTitle={i18n.i18n('total_price_title')}
                            textStyle={{
                                color: ' #000000',
                                fontWeight: 'bold',
                                fontFamily: 'Kohinoor Bangla',
                                fontStyle: 'normal',
                            }}
                        />

                        <Input
                            value={total}
                            style={{
                                borderRadius: '5px',
                                height: '41px',
                                backgroundColor: 'rgba(196, 196, 196, 0.7)',
                            }}
                        />
                        <div style={{ textAlign: 'center' }}>
                            <ButtonL
                                text={i18n.i18n('confirm_customorder_btn')}
                                style={{
                                    margin: '20px',
                                    backgroundColor: '#FD9A26',
                                    color: 'white',
                                    width: '415px',
                                    height: '71px',
                                    fontSize: '24px',
                                }}
                                onClick={onConfirmCustomOrder}
                            />
                        </div>
                    </div>
                </Modal>
            </Spin>
        </div>
    );
};

export default ConfirmCustomOrderComponent;
