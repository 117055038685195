import * as React from 'react';

function SvgSalesGraph(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M30 0v8.447h-1.367V3.319l-14.84 21.723-4.73-6.923L.967 29.972 0 28.557 9.064 15.29l4.729 6.923L27.601 2H24.23V0L30 0zM9.402 21.528l-4.82 7.057.966 1.414 4.82-7.057-.966-1.414zm17.77-11.663l-12.788 18.72L15.35 30l12.788-18.72-.966-1.415zm-7.748 18.72L20.39 30l9.411-13.776-.966-1.414-9.411 13.775zm5.039 0L25.429 30l4.227-6.187-.967-1.415-4.226 6.187zm-14.9-.027l.967 1.415 2.131-3.12-.966-1.414-2.131 3.12z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgSalesGraph;
