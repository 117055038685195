

var ColorSettings = {
    colorPrimary: '#FD9A26',
    colorTheme: "#F8786F",
    colorWhite: "#FFFFFF",
    colorBlack: "#000000",
    colorGreen: "#27AE60",
    colorGreenDark: "#0F9D58",
    colorGreenLight: "#E9F7EF",

}

export {
    ColorSettings
}