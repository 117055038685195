import React from 'react';

import { Skeleton } from 'antd';

const SkeletonView = props => {
    return (
        <div style={{ marginTop: 20 }}>
            <Skeleton active round paragraph={{ rows: 4 }} />
            <Skeleton active round paragraph={{ rows: 4 }} />
            <Skeleton active round paragraph={{ rows: 4 }} />
        </div>
    );
};

export default SkeletonView;
