import * as React from 'react';

function NoProductImg(props) {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox="0 0 566 504"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M486.418 416.438C476.868 444.469 464.587 472.439 448.852 499.06C448.503 499.66 448.15 500.247 447.794 500.844L408.841 488.496C409.065 487.931 409.311 487.298 409.574 486.608C425.286 445.892 520.075 197.82 515.82 148.908C516.278 152.912 531.107 285.407 486.418 416.438Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M456.367 501.442C455.838 501.985 455.295 502.523 454.748 503.049L425.526 493.785C425.921 493.306 426.381 492.742 426.91 492.104C435.565 481.513 461.294 449.803 486.418 416.438C513.418 380.584 539.724 342.824 543.411 327.309C542.671 330.837 519.613 438.24 456.367 501.442Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M470.974 88.3907C470.963 88.3907 470.953 88.3939 470.942 88.3942V25.6238C470.942 18.828 468.248 12.3104 463.452 7.50504C458.657 2.69965 452.153 0 445.371 0H249.263C242.481 0 235.977 2.69965 231.182 7.50504C226.387 12.3104 223.693 18.828 223.693 25.6238V57.8032C223.151 57.8148 222.636 58.0383 222.257 58.426C221.878 58.8136 221.666 59.3345 221.665 59.8772V75.8159C221.665 76.3586 221.878 76.8795 222.257 77.2671C222.636 77.6547 223.151 77.8781 223.693 77.8895V94.5869H223.589C223.316 94.5869 223.047 94.6407 222.795 94.7451C222.543 94.8496 222.315 95.0027 222.122 95.1957C221.929 95.3887 221.777 95.6178 221.673 95.87C221.568 96.1222 221.515 96.3925 221.515 96.6654V127.487C221.515 127.76 221.568 128.03 221.673 128.283C221.777 128.535 221.929 128.764 222.122 128.957C222.315 129.15 222.543 129.303 222.795 129.407C223.047 129.512 223.316 129.566 223.589 129.566H223.693V141.807H223.66C223.388 141.807 223.118 141.861 222.867 141.965C222.615 142.07 222.386 142.223 222.194 142.416C222.001 142.609 221.848 142.838 221.744 143.09C221.64 143.342 221.586 143.612 221.586 143.885V175.009C221.586 175.282 221.64 175.552 221.744 175.804C221.848 176.056 222.001 176.285 222.194 176.478C222.386 176.671 222.615 176.824 222.867 176.929C223.118 177.033 223.388 177.087 223.66 177.087H223.693V477.93C223.693 484.725 226.387 491.243 231.182 496.048C235.977 500.854 242.481 503.553 249.263 503.553H445.371C452.153 503.553 458.657 500.854 463.452 496.048C468.248 491.243 470.942 484.725 470.942 477.93V149.428C470.953 149.428 470.963 149.431 470.974 149.431C471.459 149.431 471.939 149.336 472.387 149.15C472.835 148.964 473.243 148.691 473.585 148.348C473.928 148.004 474.2 147.596 474.386 147.147C474.572 146.698 474.667 146.217 474.667 145.73V92.0919C474.667 91.1103 474.278 90.1689 473.586 89.4748C472.893 88.7807 471.953 88.3907 470.974 88.3907Z"
                    fill="#3F3D56"
                />
                <path
                    d="M455.674 32.7964V470.75C455.672 475.968 453.602 480.972 449.92 484.661C446.237 488.35 441.243 490.422 436.036 490.422H258.598C253.391 490.422 248.397 488.35 244.715 484.661C241.032 480.972 238.962 475.968 238.96 470.75V32.7964C238.96 27.5771 241.029 22.5717 244.712 18.8811C248.395 15.1905 253.39 13.1172 258.598 13.1172H285.15V16.5706C285.152 20.8676 286.857 24.9881 289.889 28.0266C292.921 31.0651 297.033 32.7729 301.321 32.7748H391.594C395.877 32.7653 399.98 31.0539 403.005 28.016C406.03 24.9782 407.729 20.862 407.729 16.5706V13.1531H436.036C441.238 13.1531 446.228 15.2215 449.91 18.9043C453.591 22.5871 455.665 27.5834 455.674 32.7964Z"
                    fill="white"
                />
                <path
                    d="M167.945 245.744C166.896 245.336 165.77 245.165 164.648 245.241C163.525 245.317 162.433 245.638 161.448 246.183C160.462 246.728 159.608 247.482 158.946 248.394C158.283 249.305 157.828 250.351 157.612 251.457L94.3818 285.911L105.499 298.449L164.22 260.685C166.099 260.925 168.001 260.466 169.565 259.395C171.129 258.323 172.246 256.714 172.705 254.873C173.165 253.031 172.934 251.084 172.056 249.402C171.179 247.719 169.716 246.418 167.945 245.744H167.945Z"
                    fill="#FFB8B8"
                />
                <path
                    d="M151.819 493.632L159.698 488.525L143.79 455.64L132.162 463.178L151.819 493.632Z"
                    fill="#FFB8B8"
                />
                <path
                    d="M179.625 486.007L154.863 502.058L148.674 492.47L164.227 482.389C166.67 480.806 169.64 480.26 172.484 480.871C175.329 481.482 177.814 483.201 179.394 485.649L179.625 486.007L179.625 486.007Z"
                    fill="#2F2E41"
                />
                <path d="M63.4141 494.82H72.7975L77.2631 458.55H63.4141V494.82Z" fill="#FFB8B8" />
                <path
                    d="M90.8945 503.551H61.4033V492.133H79.9262C81.3666 492.133 82.7929 492.417 84.1236 492.97C85.4543 493.522 86.6635 494.332 87.682 495.352C88.7005 496.373 89.5084 497.585 90.0596 498.918C90.6108 500.252 90.8945 501.681 90.8945 503.124V503.551Z"
                    fill="#2F2E41"
                />
                <path
                    d="M278.701 71.0721H259.357C258.882 71.0721 258.426 70.883 258.09 70.5464C257.754 70.2098 257.565 69.7532 257.565 69.2772C257.565 68.8012 257.754 68.3446 258.09 68.008C258.426 67.6714 258.882 67.4823 259.357 67.4823H278.701C279.176 67.4823 279.631 67.6714 279.967 68.008C280.303 68.3446 280.492 68.8012 280.492 69.2772C280.492 69.7532 280.303 70.2098 279.967 70.5464C279.631 70.883 279.176 71.0721 278.701 71.0721Z"
                    fill="#3F3D56"
                />
                <path
                    d="M278.701 76.0978H259.357C258.882 76.0978 258.426 75.9087 258.09 75.5721C257.754 75.2355 257.565 74.7789 257.565 74.3029C257.565 73.8269 257.754 73.3703 258.09 73.0337C258.426 72.6971 258.882 72.508 259.357 72.508H278.701C279.176 72.508 279.631 72.6971 279.967 73.0337C280.303 73.3703 280.492 73.8269 280.492 74.3029C280.492 74.7789 280.303 75.2355 279.967 75.5721C279.631 75.9087 279.176 76.0978 278.701 76.0978Z"
                    fill="#3F3D56"
                />
                <path
                    d="M278.701 81.1235H259.357C258.882 81.1235 258.426 80.9344 258.09 80.5978C257.754 80.2612 257.565 79.8046 257.565 79.3286C257.565 78.8525 257.754 78.396 258.09 78.0594C258.426 77.7228 258.882 77.5337 259.357 77.5337H278.701C279.176 77.5337 279.631 77.7228 279.967 78.0594C280.303 78.396 280.492 78.8525 280.492 79.3286C280.492 79.8046 280.303 80.2612 279.967 80.5978C279.631 80.9344 279.176 81.1235 278.701 81.1235Z"
                    fill="#3F3D56"
                />
                <path
                    d="M428.649 76.1335H427.997L427.747 75.9253C428.57 74.9416 429.022 73.6986 429.022 72.4145C429.016 71.3581 428.698 70.3271 428.108 69.4518C427.517 68.5766 426.681 67.8964 425.705 67.4974C424.729 67.0984 423.657 66.9984 422.624 67.2101C421.591 67.4217 420.644 67.9356 419.902 68.6866C419.161 69.4377 418.658 70.3921 418.458 71.4294C418.258 72.4666 418.369 73.5401 418.778 74.5139C419.187 75.4878 419.874 76.3183 420.754 76.9005C421.634 77.4828 422.666 77.7905 423.72 77.7848C424.998 77.7757 426.233 77.3243 427.217 76.5069L427.46 76.7151V77.3756L431.573 81.5038L432.805 80.2689L428.649 76.1335ZM423.72 76.1335C422.986 76.1364 422.268 75.921 421.656 75.5148C421.044 75.1085 420.566 74.5295 420.283 73.851C419.999 73.1726 419.923 72.4251 420.063 71.7031C420.204 70.9812 420.555 70.3171 421.072 69.795C421.589 69.2729 422.249 68.9161 422.968 68.7698C423.687 68.6236 424.434 68.6943 425.113 68.9732C425.792 69.252 426.374 69.7264 426.784 70.3364C427.194 70.9464 427.414 71.6646 427.417 72.4002L427.417 72.4145C427.42 72.9001 427.327 73.3814 427.145 73.8311C426.962 74.2808 426.692 74.69 426.352 75.0353C426.011 75.3806 425.606 75.6554 425.159 75.8438C424.713 76.0322 424.234 76.1307 423.749 76.1335C423.739 76.1335 423.73 76.1336 423.72 76.1335H423.72Z"
                    fill="#3F3D56"
                />
                <path
                    d="M318.11 137.124H283.358C281.268 137.124 279.263 136.292 277.785 134.811C276.307 133.33 275.477 131.321 275.477 129.227C275.477 127.132 276.307 125.123 277.785 123.642C279.263 122.161 281.268 121.329 283.358 121.329H318.11C320.2 121.329 322.205 122.161 323.683 123.642C325.161 125.123 325.991 127.132 325.991 129.227C325.991 131.321 325.161 133.33 323.683 134.811C322.205 136.292 320.2 137.124 318.11 137.124Z"
                    fill="#F8786F"
                />
                <path
                    d="M424.5 154.355H279.776C278.636 154.355 277.542 153.901 276.736 153.093C275.93 152.285 275.477 151.19 275.477 150.047C275.477 148.905 275.93 147.809 276.736 147.001C277.542 146.193 278.636 145.74 279.776 145.74H424.5C425.64 145.74 426.733 146.193 427.539 147.001C428.346 147.809 428.799 148.905 428.799 150.047C428.799 151.19 428.346 152.285 427.539 153.093C426.733 153.901 425.64 154.355 424.5 154.355Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M332.937 171.586H279.776C278.636 171.586 277.542 171.132 276.736 170.324C275.93 169.516 275.477 168.421 275.477 167.278C275.477 166.136 275.93 165.04 276.736 164.232C277.542 163.424 278.636 162.971 279.776 162.971H332.937C334.077 162.971 335.171 163.424 335.977 164.232C336.783 165.04 337.236 166.136 337.236 167.278C337.236 168.421 336.783 169.516 335.977 170.324C335.171 171.132 334.077 171.586 332.937 171.586Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M318.11 328.101H283.358C281.268 328.101 279.263 327.269 277.785 325.787C276.307 324.306 275.477 322.298 275.477 320.203C275.477 318.109 276.307 316.1 277.785 314.619C279.263 313.138 281.268 312.306 283.358 312.306H318.11C320.2 312.306 322.205 313.138 323.683 314.619C325.161 316.1 325.991 318.109 325.991 320.203C325.991 322.298 325.161 324.306 323.683 325.787C322.205 327.269 320.2 328.101 318.11 328.101Z"
                    fill="#F8786F"
                />
                <path
                    d="M424.5 345.332H279.776C278.636 345.332 277.542 344.878 276.736 344.07C275.93 343.262 275.477 342.166 275.477 341.024C275.477 339.881 275.93 338.786 276.736 337.978C277.542 337.17 278.636 336.716 279.776 336.716H424.5C425.64 336.716 426.733 337.17 427.539 337.978C428.346 338.786 428.799 339.881 428.799 341.024C428.799 342.166 428.346 343.262 427.539 344.07C426.733 344.878 425.64 345.332 424.5 345.332Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M332.937 362.563H279.776C278.636 362.563 277.542 362.109 276.736 361.301C275.93 360.493 275.477 359.397 275.477 358.255C275.477 357.112 275.93 356.017 276.736 355.209C277.542 354.401 278.636 353.947 279.776 353.947H332.937C334.077 353.947 335.171 354.401 335.977 355.209C336.783 356.017 337.236 357.112 337.236 358.255C337.236 359.397 336.783 360.493 335.977 361.301C335.171 362.109 334.077 362.563 332.937 362.563Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M318.11 416.409H283.358C281.268 416.409 279.263 415.577 277.785 414.096C276.307 412.615 275.477 410.606 275.477 408.512C275.477 406.417 276.307 404.408 277.785 402.927C279.263 401.446 281.268 400.614 283.358 400.614H318.11C320.2 400.614 322.205 401.446 323.683 402.927C325.161 404.408 325.991 406.417 325.991 408.512C325.991 410.606 325.161 412.615 323.683 414.096C322.205 415.577 320.2 416.409 318.11 416.409Z"
                    fill="#F8786F"
                />
                <path
                    d="M424.5 433.64H279.776C278.636 433.64 277.542 433.186 276.736 432.379C275.93 431.571 275.477 430.475 275.477 429.333C275.477 428.19 275.93 427.094 276.736 426.287C277.542 425.479 278.636 425.025 279.776 425.025H424.5C425.64 425.025 426.733 425.479 427.539 426.287C428.346 427.094 428.799 428.19 428.799 429.333C428.799 430.475 428.346 431.571 427.539 432.379C426.733 433.186 425.64 433.64 424.5 433.64Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M332.937 450.871H279.776C278.636 450.871 277.542 450.417 276.736 449.61C275.93 448.802 275.477 447.706 275.477 446.564C275.477 445.421 275.93 444.325 276.736 443.517C277.542 442.71 278.636 442.256 279.776 442.256H332.937C334.077 442.256 335.171 442.71 335.977 443.517C336.783 444.325 337.236 445.421 337.236 446.564C337.236 447.706 336.783 448.802 335.977 449.61C335.171 450.417 334.077 450.871 332.937 450.871Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M444.919 283.228H168.367C166.753 283.226 165.205 282.583 164.063 281.439C162.921 280.295 162.279 278.744 162.277 277.126V201.74C162.279 200.122 162.921 198.571 164.063 197.427C165.205 196.283 166.753 195.639 168.367 195.638H444.919C446.534 195.639 448.082 196.283 449.223 197.427C450.365 198.571 451.007 200.122 451.009 201.74V277.126C451.007 278.744 450.365 280.295 449.223 281.439C448.082 282.583 446.534 283.226 444.919 283.228Z"
                    fill="#F8786F"
                />
                <path
                    d="M68.946 372.916C71.5897 391.503 57.5901 473.853 59.1448 473.846L79.2486 473.738C79.965 472.36 89.193 427.925 92.0875 420.796C96.1283 410.903 100.205 400.887 101.795 390.319C103.293 380.397 102.555 370.288 101.58 360.301C101.626 360.004 101.6 359.7 101.505 359.415C101.41 359.13 101.249 358.872 101.035 358.661C100.821 358.451 100.56 358.295 100.273 358.205C99.9871 358.116 99.6837 358.096 99.3881 358.147L72.4136 356.754C66.8539 356.467 65.5356 355.247 66.381 360.717C66.9685 364.803 68.3585 368.78 68.946 372.916Z"
                    fill="#2F2E41"
                />
                <path
                    d="M74.2475 327.72C68.7666 335.618 63.0206 344.707 64.8404 354.163C66.2232 361.342 71.6826 366.906 76.8339 372.111C97.4248 392.832 138.026 484.256 138.026 484.256C157.62 471.692 137.56 484.615 157.442 471.333C155.68 471.197 115.637 326.952 112.32 326.758C98.9007 325.868 87.3228 324.554 74.2475 327.72Z"
                    fill="#2F2E41"
                />
                <path
                    d="M95.9262 252.551C93.1423 250.063 73.5782 242.39 73.5782 242.39C73.2185 242.343 72.8535 242.354 72.4973 242.423C71.3839 242.705 70.8759 244.091 71.1137 245.212C71.3516 246.333 69.4005 264.758 68.8979 273.137C68.0169 287.76 76.6102 320.883 76.5346 321.896C76.2401 325.508 74.6618 328.894 72.0866 331.439C72.7559 332.271 115.264 333.941 118.155 334.927C118.739 334.185 114.864 281.645 114.686 278.677C114.388 274.171 99.6013 255.963 95.9262 252.551Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M65.6077 366.553C66.615 366.051 67.5002 365.333 68.2009 364.451C68.9016 363.569 69.4007 362.543 69.6631 361.447C69.9255 360.35 69.9448 359.21 69.7197 358.105C69.4945 357 69.0304 355.958 68.36 355.053L86.295 255.587L69.5585 255.366L57.1037 353.725C55.6602 354.954 54.717 356.672 54.4527 358.551C54.1883 360.431 54.6213 362.343 55.6696 363.924C56.7178 365.505 58.3084 366.646 60.1401 367.131C61.9717 367.616 63.9172 367.41 65.6077 366.553H65.6077Z"
                    fill="#FFB8B8"
                />
                <path
                    d="M65.464 262.968C64.7661 263.019 64.0709 262.837 63.4866 262.451C62.4908 261.596 70.6154 248.522 72.2202 248.329C77.4432 247.725 82.8166 247.524 87.882 248.939C92.9473 250.353 97.7117 253.57 99.9757 258.33C100.921 260.311 94.065 270.284 90.9484 268.819C87.2156 267.067 70.3789 263.09 65.464 262.968Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M83.8986 241.319C94.2809 241.319 102.698 232.885 102.698 222.48C102.698 212.076 94.2809 203.642 83.8986 203.642C73.5162 203.642 65.0996 212.076 65.0996 222.48C65.0996 232.885 73.5162 241.319 83.8986 241.319Z"
                    fill="#FFB8B8"
                />
                <path
                    d="M65.4338 253.628C68.0461 253.902 70.6853 253.515 73.1099 252.503C75.5345 251.49 77.667 249.885 79.3124 247.833C80.9578 245.782 82.0636 243.349 82.5285 240.759C82.9933 238.168 82.8026 235.502 81.9736 233.004C81.2254 230.75 79.9749 228.685 79.2898 226.411C78.6047 224.137 78.586 221.448 80.1056 219.625C83.0786 216.058 88.809 218.302 93.3924 217.593C98.2762 216.837 102.023 212.142 102.414 207.205C102.805 202.269 100.237 197.384 96.4147 194.245C92.5924 191.106 87.6626 189.561 82.7446 189.076C76.4287 188.455 70.0593 189.473 64.2498 192.033C58.4404 194.593 53.3865 198.609 49.5748 203.694C45.7632 208.778 43.3221 214.761 42.4867 221.065C41.6513 227.37 42.4497 233.783 44.805 239.689C46.8597 244.841 50.2159 249.639 54.9829 252.46C59.7499 255.282 66.0459 255.824 70.797 252.976"
                    fill="#2F2E41"
                />
                <path
                    d="M266.72 230.1H218.679C215.628 230.1 213.146 226.557 213.146 222.202C213.146 217.847 215.628 214.305 218.679 214.305H266.72C269.771 214.305 272.253 217.847 272.253 222.202C272.253 226.557 269.771 230.1 266.72 230.1Z"
                    fill="white"
                />
                <path
                    d="M395.842 247.331H217.444C216.304 247.331 215.211 246.877 214.405 246.069C213.598 245.261 213.146 244.165 213.146 243.023C213.146 241.88 213.598 240.785 214.405 239.977C215.211 239.169 216.304 238.715 217.444 238.715H395.842C396.982 238.715 398.075 239.169 398.881 239.977C399.688 240.785 400.14 241.88 400.14 243.023C400.14 244.165 399.688 245.261 398.881 246.069C398.075 246.877 396.982 247.331 395.842 247.331Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M304.279 264.561H217.444C216.304 264.561 215.211 264.108 214.405 263.3C213.598 262.492 213.146 261.396 213.146 260.254C213.146 259.111 213.598 258.015 214.405 257.208C215.211 256.4 216.304 255.946 217.444 255.946H304.279C305.419 255.946 306.512 256.4 307.319 257.208C308.125 258.015 308.578 259.111 308.578 260.254C308.578 261.396 308.125 262.492 307.319 263.3C306.512 264.108 305.419 264.561 304.279 264.561Z"
                    fill="#E6E6E6"
                />
                <path d="M566 502.564H0V504H566V502.564Z" fill="#3F3D56" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="566" height="504" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default NoProductImg;
