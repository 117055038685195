import './colors.css';
import 'antd/dist/antd.less';
import Routing from './Routing/routing';
import { OrderContextProvider } from './Context/OrderContext';

function App() {
  return (
    <OrderContextProvider>
      <div className="App">
        <Routing />
      </div>
    </OrderContextProvider>

  );
}

export default App;
