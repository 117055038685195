import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import FactorySalesInvoice from 'Component/Invoice/FactorySalesInvoice';
import ShowroomInvoice from 'Component/Invoice/ShowroomInvoice';
import ProductionInvoice from 'Component/Invoice/ProductionInvoice';
import CustomOrderComponent from 'View/OrderPage/CustomOrder/CustomOrderComponent';
import FactoryOrderComponent from 'View/OrderPage/FactoryOrder/FactoryOrderComponent';
import ProductionOrder from 'View/OrderPage/ProductionOrder/ProductionOrder';
import EditShowroomOrderComponent from 'View/OrderPage/ShowroomOrder/EditShowroomOrderComponent';
import ShowroomOrderComponent from 'View/OrderPage/ShowroomOrder/ShowroomOrderComponent';
import AddproductForm from 'View/ProductPage/AddProduct/AddProductForm';
import AllProductList from 'View/ProductPage/AllProducts/AllProductList';
import AddCategoryComponent from 'View/ProductPage/Category/AddCategory/AddCategoryComponent';
import EditCategory from 'View/ProductPage/Category/EditCategory/EditCategory';
import ProductCategory from 'View/ProductPage/Category/ProductCategory';
import EditproductForm from 'View/ProductPage/EditProduct/EditProduct';
import Profile from 'View/Profile/profileDashboard';
import PaymentDashboard from 'View/Payment/paymentDashboard';
import Dashboard from 'View/Dashboard/dashboard';
import LoginPage from 'View/Auth/login';
import { useIsAuthenticated } from 'Store/authToken';
import PageNotFound from 'View/PageNotFound/PageNotFound';
import { useEffect } from 'react';
import { useSetTenant } from 'Store/userDetails';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
    console.log('isAuthenticated', isAuthenticated),
    (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
            }
        />
    )
);

const Routing = () => {
    const [isAuthenticated, setIsUserAuthenticated] = useIsAuthenticated();

    return (
        <Router>
            <div className="App">
                <Switch>
                    <Route exact path="/login" component={LoginPage} />
                    <PrivateRoute
                        exact
                        path="/"
                        component={ShowroomOrderComponent}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                        exact
                        path="/dashboard"
                        component={Dashboard}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                        exact
                        path="/product"
                        component={AllProductList}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                        exact
                        path="/product/add"
                        component={AddproductForm}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                        exact
                        path="/product/edit/:id"
                        component={EditproductForm}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                        exact
                        path="/category"
                        component={ProductCategory}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                        exact
                        path="/category/add"
                        component={AddCategoryComponent}
                        isAuthenticated={isAuthenticated}
                    />

                    <PrivateRoute
                        exact
                        path="/category/edit/:id"
                        component={EditCategory}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                        exact
                        path="/order/showroom-order"
                        component={ShowroomOrderComponent}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                        exact
                        path="/order/production-order"
                        component={ProductionOrder}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                        exact
                        path="/order/factory-order"
                        component={FactoryOrderComponent}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                        exact
                        path="/order/custom-order"
                        component={CustomOrderComponent}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                        exact
                        path="/order/showroom/invoice/:id"
                        component={ShowroomInvoice}
                        isAuthenticated={isAuthenticated}
                    />

                    <PrivateRoute
                        exact
                        path="/order/production/invoice"
                        component={ProductionInvoice}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                        exact
                        path="/order/factory-sales/invoice"
                        component={FactorySalesInvoice}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                        exact
                        path="/order/showroom/update/:id"
                        component={EditShowroomOrderComponent}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                        exact
                        path="/profile"
                        component={Profile}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                        exact
                        path="/payment"
                        component={PaymentDashboard}
                        isAuthenticated={isAuthenticated}
                    />
                    <Route component={PageNotFound} />
                </Switch>
            </div>
        </Router>
    );
};

export default Routing;
