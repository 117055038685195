import { Spin, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ColorSettings, H1 } from '../../Typography';
import './ShowroomInvoice.scss';
import i18n from '../../i18n';
import { ButtonXS, ButtonXXS } from '../Button';
import { PrinterOutlined } from '@ant-design/icons';
import Function from '../Service/Function';
import moment from 'moment';
import { numberWithCommas } from '../../Util/constants';
import { useGetTenant } from 'Store/userDetails';
import { useProductionAtom } from 'Store/productionsStore';
const columns = [
    {
        title: 'SL',
        dataIndex: 'key',
    },
    {
        title: 'Product Name',
        dataIndex: 'name',
    },

    {
        title: 'Total Order',
        dataIndex: 'quantity',
    },
    {
        title: 'Unit',
        dataIndex: 'unit',
    },
];

const ShowroomInvoice = () => {
    const productionList = useProductionAtom();

    return (
        <div className="showroom-invoice">
            <div className="print-btn" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonXXS
                    leftIcon={<PrinterOutlined />}
                    onClick={e => window.print()}
                    text={i18n.i18n('print_btn')}
                    style={{
                        backgroundColor: '#4890FC',
                        width: '161px',
                        fontSize: '19px',
                        borderRadius: '5px',
                        boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.25)',
                    }}
                />
            </div>
            <br />
            {productionList.map((product, index) => (
                <div style={{ marginBottom: '2rem' }}>
                    <H1
                        textTitle={product.categoryName}
                        textStyle={{
                            color: ColorSettings.colorBlack,
                            fontWeight: 'bold',
                            lineHeight: '5rem',
                        }}
                    />
                    <Table
                        className="showroom-order-table"
                        columns={columns}
                        dataSource={productionList[index].data}
                        pagination={false}
                    />
                </div>
            ))}
        </div>
    );
};

export default ShowroomInvoice;
