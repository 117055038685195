import './style.css';

import PropTypes from 'prop-types';

import ButtonContent from './ButtonContent';
import { H4 } from '../../Typography';

const ButtonXL = ({ text, style, leftIcon, rightIcon, onClick, htmlType }) => {
    return (
        <div>
            <button
                className="ButtonXL"
                variant="contained"
                color="primary"
                style={{ ...style }}
                onClick={onClick}
                type={htmlType}
            >
                <ButtonContent
                    text={<H4 textTitle={text} />}
                    leftIcon={leftIcon}
                    rightIcon={rightIcon}
                />
            </button>
        </div>
    );
};

ButtonXL.defaultProps = {
    text: 'Button XL',
    htmlType: 'button',
};

ButtonXL.propType = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export { ButtonXL };
