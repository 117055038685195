import * as React from 'react';

function SvgLogout(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path stroke="#000" strokeWidth={2} d="M1 1h28v28H1z" />
            <path
                d="M20.125 8v8.571h-9.534l2.418-2.775-1.322-1.51L7 17.643 11.688 23l1.321-1.51-2.418-2.776H22V8h-1.875z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgLogout;
