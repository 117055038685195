import './ProductionOrder.scss';
import React, { useState, useEffect } from 'react';
import { Table, Tabs, Row, Col, Input, Select, Button, Modal, Tooltip, Spin } from 'antd';
import MainMenu from 'Component/Menu/mainMenu';
import { ButtonXS, ButtonXXS } from 'Component/Button';
import moment from 'moment';
import i18n from 'i18n';
import { useHistory } from 'react-router-dom';
import SvgRefresh from 'Component/Icon/Refresh';
import { PrinterOutlined } from '@ant-design/icons';
import DatePicker from 'Component/Date Picker/dateRangePicker';
import { ColorSettings, H1 } from 'Typography';

import RemainingOrderComponent from 'Component/Orders/RemainingOrderComponent';
import Function from 'Component/Service/Function';
import { useGetTenant } from 'Store/userDetails';
import { useSetProductionAtom, useProductionAtom } from 'Store/productionsStore';
import { PRODUCTION_INVOICE_ROUTE } from 'Util/constants';
import NoOrdersComponent from 'Component/Orders/NoOrdersComponent';
import { groupBy } from 'lodash';
const { Option } = Select;
const { RangePicker } = DatePicker;

const ProductionOrder = () => {
    const tenant = useGetTenant();
    const setProductionAtom = useSetProductionAtom();
    const productionList = useProductionAtom();
    let history = useHistory();
    const [groupProduct, setGroupProduct] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const columns = [
        {
            title: 'SL',
            dataIndex: 'key',
        },
        {
            title: 'Product Name',
            dataIndex: 'name',
        },
        // {
        //     title: 'Product Code',
        //     dataIndex: 'code',
        //     sorter: (a, b) => a.code - b.code,
        //     sortDirections: ['descend'],
        //     responsive: ['md'],
        // },
        {
            title: 'Total Order',
            dataIndex: 'quantity',
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
        },
    ];

    useEffect(() => {
        if (productionList.length > 0) return;
        let startDate = moment().startOf('day').utc(true).toISOString();
        let endDate = '';

        fetchProductList(startDate, endDate);
    }, []);

    const fetchProductList = (startDate, endDate) => {
        console.log(`startDate: ${startDate}, endDate: ${endDate}`);
        setLoading(true);
        try {
            let extraurl = `product_orders?tenant=${tenant}&startDate=${startDate}&endDate=${endDate}`;

            Function.getFetch(extraurl)
                .then(response => response.json())
                .then(responseJson => {
                    let list = responseJson._embedded.collection
                        .sort((a, b) => a.code - b.code)
                        .map((v, i) => {
                            // Parse the string as a float and format it
                            const formattedNumber = parseFloat(v.quantity).toFixed(2);

                            // Convert the formatted number back to a string
                            const formattedString = parseFloat(formattedNumber).toString();
                            return {
                                ...v,
                                key: i + 1,
                                quantity: formattedString,
                            };
                        });
                    const groupByProductCategory = groupBy(list, 'categoryId');
                    const categoryWiseProducts = Object.keys(groupByProductCategory).map(keys => {
                        const details = groupByProductCategory[keys];
                        const categoryName = details[0].categoryName || 'Others';
                        const id = details[0].categoryId || '6666';
                        const data = details;
                        return { id, categoryName, data };
                    });
                    console.log('get product list', categoryWiseProducts);
                    setProductionAtom(categoryWiseProducts);
                    setLoading(false);
                    if (
                        responseJson.statusCode == 409 ||
                        responseJson.statusCode == 404 ||
                        responseJson.statusCode == 400 ||
                        responseJson.statusCode == 500
                    ) {
                        alert(responseJson.message);
                        setLoading(false);
                    }
                })
                .catch(err => console.warn('Catch Error', err));
        } catch (error) {
            alert(error);
        }
    };

    const onCustomDateClick = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onDateChange = e => {
        if (e == 'today') {
            let startDate = moment().startOf('day').utc(true).toISOString();
            let endDate = '';
            console.log(startDate, endDate);
            fetchProductList(startDate, endDate);
        } else if (e == 'yesterday') {
            let startDate = moment().subtract(1, 'days').startOf('day').utc(true).toISOString();
            let endDate = moment().subtract(1, 'days').endOf('day').utc(true).toISOString();
            console.log(startDate, endDate);
            fetchProductList(startDate, endDate);
        } else if (e == 'thismonth') {
            let startDate = moment().startOf('month').utc(true).toISOString();
            let endDate = '';
            console.log(startDate, endDate);
            fetchProductList(startDate, endDate);
        } else {
        }
    };

    const onRefresh = () => {
        let startDate = moment().startOf('day').utc(true).toISOString();
        let endDate = '';
        fetchProductList(startDate, endDate);
    };

    return (
        <MainMenu>
            <Spin spinning={loading}>
                <div
                    className="product-order"
                    style={{
                        padding: '50px 20px 0 20px',
                        fontFamily: 'KohinoorBangla-Regular',
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Select
                                placeholder="Select Date"
                                defaultValue="today"
                                style={{ width: 115 }}
                                onChange={onDateChange}
                            >
                                <Option value="today">Today</Option>
                                <Option value="yesterday">Yesterday</Option>
                                <Option value="thismonth">This Month</Option>
                            </Select>
                            {/* <div style={{ marginLeft: '100px', fontStyle: 'normal', fontWeight: 'normal', fontSize: '19px', lineHeight: '28px', color: '#000000' }}>
								<p>Time Remaining: {'02:15:30'}</p>
							</div> */}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {/* <RemainingOrderComponent remaining_list={remaining_list} remaining_col={remaining_col} /> */}
                            <ButtonXXS
                                leftIcon={<PrinterOutlined />}
                                onClick={e => history.push(PRODUCTION_INVOICE_ROUTE)}
                                text={i18n.i18n('print_btn')}
                                style={{
                                    backgroundColor: '#4890FC',
                                    width: '161px',
                                    fontSize: '19px',
                                    borderRadius: '5px',
                                    boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.25)',
                                }}
                            />
                            <ButtonXS
                                onClick={onRefresh}
                                leftIcon={<SvgRefresh />}
                                text={i18n.i18n('refresh_btn')}
                                style={{
                                    marginLeft: '50px',
                                    backgroundColor: '#FD9A26',
                                    width: '161px',
                                    fontSize: '19px',
                                    borderRadius: '5px',
                                    boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.25)',
                                }}
                            />
                        </div>
                    </div>
                    <br />
                    <br />
                    {productionList.length === 0 && <NoOrdersComponent />}
                    {productionList.map((product, index) => (
                        <div style={{ marginBottom: '2rem' }}>
                            <H1
                                textTitle={product.categoryName}
                                textStyle={{
                                    color: ColorSettings.colorBlack,
                                    fontWeight: 'bold',
                                    lineHeight: '5rem',
                                }}
                            />
                            <Table
                                className="showroom-order-table"
                                columns={columns}
                                dataSource={productionList[index].data}
                                pagination={false}
                                locale={{ emptyText: <NoOrdersComponent /> }}
                            />
                        </div>
                    ))}
                    <div>
                        <Modal
                            title="Select Date Range"
                            open={isModalVisible}
                            onOk={handleOk}
                            onCancel={handleCancel}
                        >
                            <DatePicker />
                        </Modal>
                    </div>
                </div>
            </Spin>
        </MainMenu>
    );
};

export default ProductionOrder;
