import React, { useState } from 'react';
import { MainMenu } from 'Component/index';
import { Table, Input, Space } from 'antd';

import DatePicker from 'Component/Date Picker/dateRangePicker';
import SkeletonView from 'Component/Skeleton/skeleton';

import i18n from 'i18n';
import ConfirmPaymentComponent from 'Component/Payment/ConfirmPaymentComponent';
const { Search } = Input;
const columns = [
    {
        title: 'No',
        dataIndex: 'key',
        width: '10%',
    },
    {
        title: i18n.i18n('customer_name'),
        dataIndex: 'userName',
        width: '30%',
    },
    {
        title: i18n.i18n('mobile_number'),
        dataIndex: 'title',
        width: '30%',
    },
    {
        title: i18n.i18n('total_debt'),
        dataIndex: 'details',
        width: '30%',
    },
    {
        title: '',
        key: 'action',
        width: '10%',
        render: (text, record) => (
            <div>
                <ConfirmPaymentComponent rowData={record} />
            </div>
        ),
    },
];
const searchRenderView = () => {
    return (
        <div style={{ marginTop: 15 }}>
            <Space direction="vertical">
                <Search
                    placeholder="Search"
                    onSearch={e => console.log(e)}
                    style={{ width: 817 }}
                    allowClear
                    enterButton
                />
            </Space>
        </div>
    );
};
const PaymentDashboard = props => {
    const [loading, setLoading] = useState(true);
    const [customOrderData, setCustomOrderData] = useState([
        {
            accepted: false,
            amount: 0,
            createdAt: '2022-01-22T11:42:04.859Z',
            details: '10 packet mistyhsjsjdh',
            id: '01FT0SFVHVAA7F4H1MTQVQVAFF',
            key: 2,
            orderId: '01FT0SFVH45S27THJ0YYYKHDTK',
            quantity: '0',
            tenantId: 'haque',
            title: '10 packet misty',
            updatedAt: '2022-01-22T11:42:04.860Z',
            userId: 'X9OZYO9NfnRGvcvXjCczE84AuK92',
            userName: 'Apphouse BD',
        },
        {
            accepted: false,
            amount: 0,
            createdAt: '2022-01-22T11:42:04.859Z',
            details: '10 packet mistyhsjsjdh',
            id: '01FT0SFVHVAA7F4H1MTQVQVAFF',
            key: 2,
            orderId: '01FT0SFVH45S27THJ0YYYKHDTK',
            quantity: '0',
            tenantId: 'haque',
            title: '10 packet misty',
            updatedAt: '2022-01-22T11:42:04.860Z',
            userId: 'X9OZYO9NfnRGvcvXjCczE84AuK92',
            userName: 'Apphouse BD',
        },
        {
            accepted: false,
            amount: 0,
            createdAt: '2022-01-22T11:42:04.859Z',
            details: '10 packet mistyhsjsjdh',
            id: '01FT0SFVHVAA7F4H1MTQVQVAFF',
            key: 2,
            orderId: '01FT0SFVH45S27THJ0YYYKHDTK',
            quantity: '0',
            tenantId: 'haque',
            title: '10 packet misty',
            updatedAt: '2022-01-22T11:42:04.860Z',
            userId: 'X9OZYO9NfnRGvcvXjCczE84AuK92',
            userName: 'Apphouse BD',
        },
    ]);

    return (
        <MainMenu>
            <div style={{ width: 400, marginTop: 30 }}>
                <DatePicker getDate={e => this.onDateChange(e)} />
            </div>
            {searchRenderView()}
            <br />

            <Table
                // className="custom-order-table"
                columns={columns}
                dataSource={customOrderData}
                bordered
            />
            {loading ? <SkeletonView /> : null}
        </MainMenu>
    );
};

export default PaymentDashboard;
