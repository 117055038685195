import * as React from 'react';

function SvgShoppingCart(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9.19 17.611H26.87L30 6.444h-4.604l-1.653-5.106-6.894 2.234.943 2.872h-.325l-1.702-5.106-6.894 2.234.943 2.872H6.13l-.766-3.061C4.978 1.852 3.643.768 2.106.768H0v1.28h2.106c.955 0 1.788.637 2.045 1.598l3.386 13.249a2.774 2.774 0 00-1.157 2.26c0 1.53 1.28 2.81 2.81 2.81h17.234v-1.28H9.19c-.833 0-1.53-.704-1.53-1.53 0-.84.703-1.544 1.53-1.544zM22.916 2.93l1.151 3.447H19.09l-.704-1.978 4.53-1.47zm-7.977 0l1.15 3.447h-4.977l-.704-1.978 4.53-1.47zM28.34 7.656l-2.425 8.682H8.682L6.447 7.656H28.34zM20.81 23.868a2.697 2.697 0 00-2.682 2.682 2.697 2.697 0 002.682 2.682 2.658 2.658 0 002.682-2.682 2.697 2.697 0 00-2.682-2.682zm0 4.151c-.833 0-1.47-.704-1.47-1.469 0-.833.705-1.47 1.47-1.47.833 0 1.47.637 1.47 1.47s-.705 1.47-1.47 1.47zM10.977 23.868a2.697 2.697 0 00-2.681 2.682 2.697 2.697 0 002.681 2.682 2.697 2.697 0 002.682-2.682 2.697 2.697 0 00-2.682-2.682zm0 4.151c-.832 0-1.469-.704-1.469-1.469 0-.833.704-1.47 1.47-1.47.765 0 1.469.637 1.469 1.47s-.704 1.47-1.47 1.47z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgShoppingCart;
